import { store } from '../store/reducers/store'
import CustomAxios from '../utils/Axios';
import { Constants } from '../utils/Constants';
import { Utilities } from '../utils/Utilities';
import SocketHandler from './SocketHandler';
import config from '../config.json';
import { loginRequest, msalConfig } from '../appConfig'
import { PublicClientApplication } from "@azure/msal-browser";
import { setToken } from '../store/actions/chat';

const getRemoteUrl = (searchTarget) => {
    return Constants.URI[searchTarget];
}

const socketHandler = SocketHandler;

const isTokenExpired = (state) => {
    return state.chat.tokenExpires === undefined || state.chat.tokenExpires.getTime() < Date.now();
}
// This should be the same instance you pass to MsalProvider
const msalInstance = new PublicClientApplication(msalConfig);

const renewToken = async () => {
    const activeAccount = msalInstance.getActiveAccount(); // This will only return a non-null value if you have logic somewhere else that calls the setActiveAccount API
    const accounts = msalInstance.getAllAccounts();
    if (!activeAccount && accounts.length === 0) {
        return undefined;
    }
    const request = {
        ...loginRequest,
        account: activeAccount || accounts[0]
    };
    await msalInstance.initialize();

    const authResult = await msalInstance.acquireTokenSilent(request).catch((error) => console.log(error));

    return { token: authResult.accessToken, expiresOn: authResult.expiresOn };
}

export const GPTService = {
    abortController: undefined,
    handleChat: async (question, historyLimit, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)) {
            const token = await renewToken();
            store.dispatch(setToken(token))
        }

        GPTService.abortController = new AbortController();

        const documents = (state.chat.files.length > 0 ? await Promise.all(state.chat.files.map((file) => Utilities.docToBase64(file))) : undefined);
        const target = (documents !== undefined ? Constants.SearchTarget.Document : state.chat.searchTarget)

        if (config.features.streaming) {
            return await socketHandler.getStreamData(getRemoteUrl(target),
                state.chat.token,
                {
                    question: question,
                    documents: documents,
                    chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
                    gpt_model: state.chat.gptVersion,
                    temperature: state.chat.temperature / 10,
                    search_type: state.chat.searchType,
                    deployement: target.toLocaleLowerCase(),
                    number_of_documents: state.chat.nbOfDocuments,
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                }, onUpdate);
        } else {
            return CustomAxios.post(
                getRemoteUrl(target),
                {
                    question: question,
                    documents: documents,
                    chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
                    gpt_model: state.chat.gptVersion,
                    temperature: state.chat.temperature / 10,
                    search_type: state.chat.searchType,
                    deployement: target.toLocaleLowerCase(),
                    number_of_documents: state.chat.nbOfDocuments,
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                        "Authorization": `Bearer ${state.chat.token}`
                    },
                    signal: GPTService.abortController.signal,
                }
            ).then(r => r.data);
        }
    },
    regenerate: async (question, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)) {
            const token = await renewToken();
            store.dispatch(setToken(token))
        }

        GPTService.abortController = new AbortController();

        if (config.features.streaming) {
            return await socketHandler.getStreamData(Constants.URI.Regenerate,
                state.chat.token,
                {
                    question: question.question,
                    gpt_model: question.gpt_model,
                    temperature: question.temperature,
                    deployement: "regenerate",
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                    chat_history: [],
                    chunks: question.chunks
                }, onUpdate);
        } else {
            return CustomAxios.post(
                Constants.URI.Regenerate,
                {
                    question: question.question,
                    gpt_model: question.gpt_model,
                    temperature: question.temperature,
                    deployement: "regenerate",
                    userid: state.chat.user ? state.chat.user.homeAccountId : "",
                    useremail: state.chat.user ? state.chat.user.username : "",
                    chat_history: [],
                    chunks: question.chunks
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                        "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                        "Authorization": `Bearer ${state.chat.token}`
                    },
                    signal: GPTService.abortController.signal,
                }
            ).then(r => r.data);
        }
    },
    cancelChat: () => {
        socketHandler.cancelStream();
    },
    /* ** Translations ** */
    handleTanslation: async (text, language, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)) {
            const token = await renewToken();
            store.dispatch(setToken(token))
        }

        GPTService.abortController = new AbortController();
        const target = Constants.SearchTarget.Chat;

        // if (config.features.streaming) {
        // /!\ NOT HANDLED FOR NOW /!\
        // return await socketHandler.getStreamData(getRemoteUrl(target),
        //     state.chat.token,
        //     {
        //         question: question,
        //         documents: documents,
        //         chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
        //         gpt_model: state.chat.gptVersion,
        //         temperature: state.chat.temperature / 10,
        //         search_type: state.chat.searchType,
        //         deployement: target.toLocaleLowerCase(),
        //         number_of_documents: state.chat.nbOfDocuments,
        //         userid: state.chat.user ? state.chat.user.homeAccountId : "",
        //         useremail: state.chat.user ? state.chat.user.username : "",
        //     }, onUpdate);
        // } else {
        return CustomAxios.post(
            Constants.URI.Translation,
            {
                question: text,
                documents: undefined,
                chat_history: [],
                gpt_model: state.chat.gptVersion,
                temperature: state.chat.temperature / 10,
                search_type: state.chat.searchType,
                deployement: target.toLocaleLowerCase(),
                custom_prompt: `Vous êtes un traducteur vers ${language}. Ne répondez pas au message mais traduisez le message en ${language}. Vous devez traduire le message écrit par l'utilisateur en ${language}.`,
                number_of_documents: 0,
                userid: state.chat.user ? state.chat.user.homeAccountId : "",
                useremail: state.chat.user ? state.chat.user.username : "",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                    "Authorization": `Bearer ${state.chat.token}`
                },
                signal: GPTService.abortController.signal,
            }
        ).then(r => r.data.answer);
        // }
    },
    handleDocumentTranslation: async (file, source_language, dest_language, onUpdate) => {
        const state = store.getState();

        if (isTokenExpired(state)) {
            const token = await renewToken();
            store.dispatch(setToken(token))
        }

        GPTService.abortController = new AbortController();
        const target = Constants.SearchTarget.Document;

        // if (config.features.streaming) {
        // /!\ NOT HANDLED FOR NOW /!\
        // return await socketHandler.getStreamData(getRemoteUrl(target),
        //     state.chat.token,
        //     {
        //         question: question,
        //         documents: documents,
        //         chat_history: (historyLimit !== undefined ? state.chat.history.slice(0, historyLimit) : state.chat.history).filter(h => h.answer !== undefined).map(h => ({ question: h.question, answer: h.answer })),
        //         gpt_model: state.chat.gptVersion,
        //         temperature: state.chat.temperature / 10,
        //         search_type: state.chat.searchType,
        //         deployement: target.toLocaleLowerCase(),
        //         number_of_documents: state.chat.nbOfDocuments,
        //         userid: state.chat.user ? state.chat.user.homeAccountId : "",
        //         useremail: state.chat.user ? state.chat.user.username : "",
        //     }, onUpdate);
        // } else {
        return CustomAxios.post(
            Constants.URI.DocumentTranslation,
            {
                "doc_type": "docx",
                "source_language": source_language,
                "target_language": dest_language,
                "doc_b64_content": file.base64
                // question: `Traduit moi en ${language}, ne répond qu'avec la contenu du document traduit`,
                // documents: [file],
                // chat_history: [],
                // gpt_model: state.chat.gptVersion,
                // temperature: state.chat.temperature / 10,
                // search_type: state.chat.searchType,
                // deployement: target.toLocaleLowerCase(),
                // number_of_documents: 0,
                // userid: state.chat.user ? state.chat.user.homeAccountId : "",
                // useremail: state.chat.user ? state.chat.user.username : "",
            },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
                    "Authorization": `Bearer ${state.chat.token}`
                },
                signal: GPTService.abortController.signal,
            }
        ).then(r => r.data.translated_doc);
        // }
    },
    /* ** Consent Part ** */
    getHasConsented: async (uuid) => {
        return await CustomAxios.post(Constants.URI.GetConsent, {
            uuid: uuid
        }, {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
            }
        }).then(r => r.data)
    },
    makeConsent: async (uuid, email) => {
        await CustomAxios.post(Constants.URI.CreateConsent, {
            uuid: uuid,
            email: email
        }, {
            headers: {
                "Content-Type": "application/json",
                "Ocp-Apim-Subscription-Key": Constants.FunctionKey,
            }
        });
    },
    /* ** Chat saving management ** */
    getUserChats: async (uuid) => {
        let state = store.getState();
        // As this can be called at the first load, we wait for token to be provided
        while (state.chat.token === undefined) {
            await new Promise(resolve => setTimeout(resolve, 500));
            state = store.getState();
        }
        return await CustomAxios.get(`${Constants.URI.GetUserChats}/${uuid}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }
        ).then(r => r.data);
    },
    getChatHistory: async (convId) => {
        const state = store.getState();
        return await CustomAxios.get(`${Constants.URI.GetChatHistory}/${convId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    addChat: async (data) => {
        const state = store.getState();
        return await CustomAxios.post(`${Constants.URI.AddChatHistory}`, data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    updateChat: async (data) => {
        const state = store.getState();
        return await CustomAxios.patch(`${Constants.URI.UpdateChatHistory}`, data,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    renameChat: async (id, title) => {
        const state = store.getState();
        return await CustomAxios.patch(`${Constants.URI.RenameChatHistory}/${id}`, { Title: title },
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    deleteChat: async (id) => {
        const state = store.getState();
        await CustomAxios.delete(`${Constants.URI.DeleteChatHistory}/${id}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    search: async (query, index) => {
        const state = store.getState();
        let idx = "";
        if (index.length > 0) {
            idx = index[0]
        }
        return await CustomAxios.get(`${Constants.URI.AzureSearch}?query=${encodeURIComponent(query)}&index=${encodeURIComponent(idx)}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${state.chat.token}`
                }
            }).then(r => r.data);
    },
    loadUserMeetings: async (customToken) => {
        return await CustomAxios.get(`${Constants.URI.GetUserMeetings}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${customToken}`
                }
            }).then(r => r.data);
    },
    loadTranscript: async (customToken, joinUrl) => {
        return await CustomAxios.get(`${Constants.URI.GetTranscript}?joinUrl=${joinUrl}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${customToken}`
                }
            }).then(r => r.data);
    },
    loadMeetingAttendance: async (customToken, meetingId) => {
        return await CustomAxios.get(`${Constants.URI.GetMeetingAttendance}?meetingId=${meetingId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${customToken}`
                }
            }).then(r => r.data);
    },
    loadMeetingAttendanceDetails: async (customToken, meetingId, fetchedMeetingId) => {
        return await CustomAxios.get(`${Constants.URI.GetMeetingAttendanceDetails}?meetingId=${meetingId}&reportId=${fetchedMeetingId}`,
            {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${customToken}`
                }
            }).then(r => r.data);
    }
}
import React from 'react'
import { useLocation } from 'react-router-dom';
import PageListItem from './PageListItem';
import { RoutesConstant } from '../../../utils/Routes';

const PageList = () => {
    const { pathname } = useLocation();
  return (
    <div className='pages'>
        {RoutesConstant.filter(r => r.isMenu).map(p => (<PageListItem title={p.title} path={p.path} isSelected={pathname === `/${p.path}`} logo={p.icon} key={`pagelink-${p.path}`} />))}
    </div>
  )
}

export default PageList
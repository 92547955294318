import React from 'react'
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store'
import format_icon from '../../../assets/images/search/formats.svg';
import MultiSelectDropdown from '../common/MultiSelectDropdown';
import { setFormat } from '../../../store/actions/search';

const SearchFormats = () => {
    const formats = useAppSelector(state => state.search.formats);
    const dispatch = useAppDispatch();

    const onChange = (values) => {
        dispatch(setFormat(values));
    }
    return <MultiSelectDropdown label={"Formats"} icon={format_icon} selection={formats} choices={[
        { value: "pdf", text: "PDF" },
        { value: "doc*", text: "Doc" },
        { value: "xls*", text: "XLS" }
    ]} onSelectionChanged={onChange} />
}

export default SearchFormats
import React, { useState } from 'react'
import { Constants } from '../../../utils/Constants';
import config from "../../../config.json"

const getChuncksUrl = (reference) => {
    if (reference.chunk_content) {
        return reference.chunk_content.metadata && reference.chunk_content.metadata.source_url ? reference.chunk_content.metadata.source_url : (reference.chunk_content.metadata.title ? reference.chunk_content.metadata.title : reference.chunk_content.source);
    } else {
        return reference.source_url ? reference.source_url : reference.title;
    }
}
const getChuncksContent = (reference) => {
    if (reference.chunk_content) {
        return reference.chunk_content.content;
    } else {
        return reference.chunk
    }
}

const ChatReferenceItem = ({ reference, index, searchTarget, entry, isManageReferences, checkedStates, setCheckedStates, unusedChunck }) => {
    const [showMore, setShowMore] = useState(false);
    const clickable = entry.deployement && (entry.deployement.toLocaleLowerCase() === Constants.SearchTarget.Web.toLocaleLowerCase() || entry.deployement.toLocaleLowerCase() === Constants.SearchTarget.Sharepoint.toLocaleLowerCase() || entry.deployement.toLocaleLowerCase() === Constants.SearchTarget.GLPI.toLocaleLowerCase());
    const fileName = (unusedChunck ?
        getChuncksUrl(reference)
        :
        (reference.url !== undefined && reference.url !== null ? reference.url : reference.document));

    const title = fileName ? (fileName.indexOf('/') !== -1 ? fileName.substring(fileName.lastIndexOf('/') + 1) : fileName) : "Untitled";
    var fileExtension = fileName ? fileName.substring(fileName.lastIndexOf('.') + 1) : "";
    const content = (unusedChunck ? getChuncksContent(reference) : reference.content);
    if (fileExtension.length > 5 && searchTarget === "Web") {
        fileExtension = "web";
    }
    else if (searchTarget === Constants.SearchTarget.Email) {
        fileExtension = "mail";
    }
    else if (fileExtension.length > 5 && searchTarget === Constants.SearchTarget.GLPI) {
        fileExtension = "ticket";
    }

    const showMoreInfo = () => {
        setShowMore(!showMore);
    }

    let radioChecked; let radioUnchecked;
    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
    if (darkMode) {
        radioChecked = require('../../../assets/images/radio_checked_dark.svg').default;
        radioUnchecked = require('../../../assets/images/radio_unchecked_dark.svg').default;
    } else {
        radioChecked = require('../../../assets/images/radio_checked.svg').default;
        radioUnchecked = require('../../../assets/images/radio_unchecked.svg').default;
    }

    return <>
        {entry.deployement === "sharepoint" && <h2> {config.homePage.text.document} </h2>}
        <div className="referenceTitle">
            <div className='leftpart'>
                {isManageReferences && (
                    <label htmlFor={`checkbox${unusedChunck ? 'Chunck' : 'Reference'}-${index}`}>
                        <input
                            type="checkbox"
                            id={`checkbox${unusedChunck ? 'Chunck' : 'Reference'}-${index}`}
                            name={`${unusedChunck ? 'chunck' : 'reference'}-${index}`}
                            value={fileName === null ? undefined : fileName}
                            checked={checkedStates[`${unusedChunck ? 'chunck' : 'reference'}-${index}`]}
                            onChange={(e) => {
                                setCheckedStates({ ...checkedStates, [`${unusedChunck ? 'chunck' : 'reference'}-${index}`]: e.target.checked });
                            }}
                        />
                        <img className="referenceCheck" src={checkedStates[`${unusedChunck ? 'chunck' : 'reference'}-${index}`] ? radioChecked : radioUnchecked} alt={checkedStates[`${unusedChunck ? 'chunck' : 'reference'}-${index}`] ? "yes" : "no"} />
                    </label>
                )}
                {clickable ?
                    <a
                        href={fileName && fileName.includes('sharepoint') ? `${fileName}?web=1` : fileName}
                        target='_blank'
                        rel="noreferrer"
                        className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`}
                    >
                        [{index + 1}] {title}
                    </a>
                    :
                    <span className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`}>
                        [{index + 1}] {title}
                    </span>

                }
                {unusedChunck &&
                    <span className="notUsedReference">
                        {config.homePage.text.references.notUsedReferences}
                    </span>
                }
            </div>
            <button className='link showmore' onClick={showMoreInfo}>{showMore ? "Show less" : "Show more"}</button>
        </div>
        <div className='referenceContent'>
            <p className={showMore ? "opened" : ""}>
                {content}
            </p>
        </div>
    </>
}

export default ChatReferenceItem
export default (() => {
    let isRunning = false;
    let socket = undefined;
    let isCancel = false;
    return {
        getStreamData: (url, token, data, onUpdate) => {
            if (socket !== undefined) {
                socket.close();
            }

            // Add only the token as a query parameter to the URL
            const modifiedUrl = `${url}?token=${encodeURIComponent(token)}`;

            return new Promise((resolve, reject) => {
                socket = new WebSocket(modifiedUrl.replace("https://", "wss://"));
                isCancel = false;
                isRunning = true;
                socket.onopen = () => {
                    console.log('WebSocket connection established');
                    socket.send(JSON.stringify(data));
                };
                socket.onmessage = (event) => {
                    if (event && event.data) {
                        const data = JSON.parse(event.data);
                        if (data) {
                            if (data.message) {
                                onUpdate(data.message);
                            } else if (data.output) {
                                resolve(JSON.parse(data.output));
                            }
                        }
                    }
                };
                socket.onerror = (error) => {
                    console.error('WebSocket error:', error);
                    reject(error);
                };
                socket.onclose = () => {
                    console.log('WebSocket connection closed');
                    isRunning = false;
                    socket = undefined;
                    if (isCancel) {
                        reject({ message: "Canceled", code: "ERR_CANCELED" });
                    }
                };
            });
        },
        cancelStream: () => {
            isCancel = true;
            if (isRunning) {
                socket.close();
            }
        }
    }
})();

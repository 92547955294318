import { createReducer } from "@reduxjs/toolkit"
import { setFiles, setLanguage, setLanguageSource, setText, translateDocument } from "../actions/translate";
import { sendTranslation } from "../actions/translate";

const initialState = {
    language: "english",
    languageSource: "french",
    sourceText: "",
    loading: false,
    translated: undefined,
    files: [],
    translatedDocuments: [],
    error: undefined
}

const chatReducer = createReducer(initialState, (builder) => {
    builder.addCase(setLanguage, (state, action) => {
        state.language = action.payload;
    });

    builder.addCase(setLanguageSource, (state, action) => {
        state.languageSource = action.payload;
    });

    builder.addCase(setFiles, (state, action) => {
        state.files = action.payload
    })

    builder.addCase(setText, (state, action) => {
        state.sourceText = action.payload;
    });

    builder.addCase(sendTranslation.fulfilled, (state, action) => {
        state.translated = action.payload;
        state.loading = false;
    });

    builder.addCase(sendTranslation.pending, (state) => {
        state.loading = true;
    });

    builder.addCase(translateDocument.pending, (state) => {
        state.loading = true;
        state.error = undefined;
    });

    builder.addCase(translateDocument.fulfilled, (state, action) => {
        const files = [...state.translatedDocuments];
        files.push({ content: action.payload, name: action.meta.arg.document, size: action.payload.length });
        state.translatedDocuments = files;
        state.loading = false;
    });

    builder.addCase(translateDocument.rejected, (state) => {
        state.error = `An unexpected error occured in translation process, please ensure that your document is not too big an retry.`;
    });
});

export default chatReducer;
import React, { useEffect, useState, createContext, useContext, useRef } from "react";
import Select from "react-select";
import "../assets/styles/base.css";
import NavBar from "./components/NavBar.js";
import PrivateLayout from "../layouts/privateLayout.js";
import { useAppDispatch } from "../store/reducers/store.js";
import { setUser } from "../store/actions/chat.js";
import config from "../config.json";
import {
    PublicClientApplication,
    InteractionRequiredAuthError,
} from "@azure/msal-browser";
import { saminutesMsalConfig, saminutesLoginRequest } from "../appConfig";
import SaminutesIcon from "../assets/images/saminutes.png";
import CopyIcon from "../assets/images/copy.png";
import WordIcon from "../assets/images/word.png";
import EmailIcon from "../assets/images/email-send.png";
// import { fetchMeetings } from "../hooks/fetchMeetings";
// import { fetchTranscript } from "../hooks/fetchTranscript";
// import {
//     fetchAttendanceReports,
//     fetchAttendanceReportDetails,
// } from "../hooks/fetchAttendance";
import { generateMeetingNotes } from "../utils/generateMeetingNotes";
import { generateQuickRecap } from "../utils/generateQuickRecap";
import { generateSummaries } from "../utils/generateSummary";
import { generateNextSteps } from "../utils/generateNextSteps";
import SpinnerSaminute from "./components/common/Spinner";
import { downloadMeetingMinutes } from "../utils/downloadMeetingMinutes";

import EditIcon from "../assets/images/edit.png";   // pencil icon
import SaveIcon from "../assets/images/save.png";   // check mark icon
import { GPTService } from "../services/GptService.js";

const saminutesMsalInstance = new PublicClientApplication(saminutesMsalConfig);
const SaminutesMsalContext = createContext(null);

function useSaminutesMsal() {
    const context = useContext(SaminutesMsalContext);
    if (!context) {
        throw new Error("useSaminutesMsal must be used within a SaminutesMsalProvider");
    }
    return context;
}

function SaminutesMsalProvider({ children }) {
    return (
        <SaminutesMsalContext.Provider value={saminutesMsalInstance}>
            {children}
        </SaminutesMsalContext.Provider>
    );
}

function SaminutesContent() {
    const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
    const saminutesMsalInstance = useSaminutesMsal();
    const dispatch = useAppDispatch();
    const [accounts, setAccounts] = useState([]);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [msalInitialized, setMsalInitialized] = useState(false);

    const [meetings, setMeetings] = useState([]);
    const [selectedMeeting, setSelectedMeeting] = useState("");
    const [meetingId, setMeetingId] = useState("");
    const [meetingTitle, setMeetingTitle] = useState("");
    const [transcriptContent, setTranscriptContent] = useState("");
    const [attendanceReports, setAttendanceReports] = useState([]);
    const [participants, setParticipants] = useState([]);
    const [meetingNotes, setMeetingNotes] = useState("");
    const [quickRecap, setQuickRecap] = useState("");
    const [summaries, setSummaries] = useState("");
    const [nextSteps, setNextSteps] = useState("");

    const [isGeneratingNotes, setIsGeneratingNotes] = useState(false);
    const [isGeneratingRecap, setIsGeneratingRecap] = useState(false);
    const [isGeneratingSummaries, setIsGeneratingSummaries] = useState(false);
    const [isGeneratingNextSteps, setIsGeneratingNextSteps] = useState(false);
    const [isFetchingMeetingId, setIsFetchingMeetingId] = useState(false);
    const [isFetchingTranscript, setIsFetchingTranscript] = useState(false);
    const [error, setError] = useState("");

    // We no longer need isSendingEmail since we're just opening a mailto link
    // const [isSendingEmail, setIsSendingEmail] = useState(false);

    // Editing states
    const [isEditingRecap, setIsEditingRecap] = useState(false);
    const [isEditingSummaries, setIsEditingSummaries] = useState(false);
    const [isEditingNextSteps, setIsEditingNextSteps] = useState(false);

    // Refs to store interim text while editing
    const recapRef = useRef(quickRecap);
    const summariesRef = useRef(summaries);
    const nextStepsRef = useRef(nextSteps);

    useEffect(() => {
        const initializeMsalInstance = async () => {
            try {
                await saminutesMsalInstance.initialize();
                setMsalInitialized(true);
                const currentAccounts = saminutesMsalInstance.getAllAccounts();
                //Forced Exclusion of the towards change tenant
                const matchingAccounts = currentAccounts.filter(a => a.tenantId !== process.env.REACT_APP_TENANTID);
                setAccounts(matchingAccounts);
                setIsAuthenticated(matchingAccounts.length > 0);
            } catch (error) {
                setError("Failed to initialize authentication.");
            }
        };

        initializeMsalInstance();
    }, [saminutesMsalInstance]);

    useEffect(() => {
        const handleAuthentication = async () => {
            if (!msalInitialized) return;

            if (!isAuthenticated) {
                try {
                    const response = await saminutesMsalInstance.loginPopup(
                        saminutesLoginRequest
                    );
                    const currentAccounts = saminutesMsalInstance.getAllAccounts();
                    setAccounts(currentAccounts);
                    setIsAuthenticated(true);
                    dispatch(setUser(response.account));
                } catch (error) {
                    console.error("Login Popup Error:", error);
                    setError("Authentication failed. Please try again.");
                }
            } else {
                dispatch(setUser(accounts[0]));
            }
        };

        handleAuthentication();
    }, [msalInitialized, isAuthenticated, accounts, dispatch, saminutesMsalInstance]);

    useEffect(() => {
        const fetchMeetingsData = async () => {
            if (!isAuthenticated) return;
            try {
                const response = await saminutesMsalInstance.acquireTokenSilent({
                    scopes: saminutesLoginRequest.scopes,
                    account: accounts[0],
                });
                const meetingsData = await GPTService.loadUserMeetings(response.accessToken);
                setMeetings(meetingsData);
            } catch (error) {
                console.error("Error fetching meetings:", error);
                setError("Failed to fetch meetings.");
            }
        };
        fetchMeetingsData();
    }, [isAuthenticated, accounts, saminutesMsalInstance]);

    useEffect(() => {
        const fetchMeetingDetails = async () => {
            if (!selectedMeeting || !isAuthenticated || accounts.length === 0) {
                return;
            }

            try {

                const account = accounts[0];
                if (!account) {
                    console.error("No account object available.");
                    setError("No authenticated account found.");
                    return;
                }
                let response;
                try {
                    response = await saminutesMsalInstance.acquireTokenSilent({
                        scopes: saminutesLoginRequest.scopes,
                        account,
                    });
                } catch (error) {
                    if (error instanceof InteractionRequiredAuthError) {
                        response = await saminutesMsalInstance.acquireTokenPopup({
                            scopes: saminutesLoginRequest.scopes,
                            account,
                        });
                    } else {
                        throw error;
                    }
                }

                const accessToken = response.accessToken;

                setIsFetchingMeetingId(true);
                const { meetingId: fetchedMeetingId, combinedTranscriptContent } = await GPTService.loadTranscript(accessToken, selectedMeeting);
                //     const { meetingId: fetchedMeetingId, combinedTranscriptContent } = await fetchTranscript(
                //         accessToken,
                //         selectedMeeting
                //     );
                setIsFetchingMeetingId(false);

                setMeetingId(fetchedMeetingId);
                setTranscriptContent(combinedTranscriptContent);

                if (!combinedTranscriptContent) {
                    setError(
                        "Sorry, no transcript is found for this meeting! Please enable transcripts for your future meetings."
                    );
                    setAttendanceReports([]);
                    setParticipants([]);
                    setMeetingNotes("");
                    setQuickRecap("");
                    setSummaries("");
                    setNextSteps("");

                    // Reset all loading states here as well
                    setIsFetchingTranscript(false);
                    setIsGeneratingNotes(false);
                    setIsGeneratingRecap(false);
                    setIsGeneratingSummaries(false);
                    setIsGeneratingNextSteps(false);
                    return;
                }

                setIsFetchingTranscript(true);
                const { reports, attendanceReportId } = await GPTService.loadMeetingAttendance(accessToken, fetchedMeetingId);
                setIsFetchingTranscript(false);
                setAttendanceReports(reports);

                if (attendanceReportId) {
                    const { participants: fetchedParticipants } = await GPTService.loadMeetingAttendanceDetails(accessToken, fetchedMeetingId, attendanceReportId);
                    setParticipants(fetchedParticipants);
                }

                setIsGeneratingNotes(true);
                const notes = await generateMeetingNotes(combinedTranscriptContent);
                setMeetingNotes(notes);
                setIsGeneratingNotes(false);

                if (notes) {
                    setIsGeneratingRecap(true);
                    const recap = await generateQuickRecap(notes);
                    setQuickRecap(recap);
                    setIsGeneratingRecap(false);

                    if (recap) {
                        setIsGeneratingSummaries(true);
                        const summariesData = await generateSummaries(notes);
                        setSummaries(summariesData);
                        setIsGeneratingSummaries(false);

                        if (summariesData) {
                            setIsGeneratingNextSteps(true);
                            const steps = await generateNextSteps(notes);
                            setNextSteps(steps);
                            setIsGeneratingNextSteps(false);
                        } else {
                            console.warn("Summaries data is empty. Skipping next steps generation.");
                        }
                    } else {
                        console.warn("Recap is empty. Skipping summaries and next steps generation.");
                    }
                } else {
                    console.warn("Meeting notes are empty. Skipping all other generations.");
                }
            } catch (error) {
                console.error("Error fetching meeting details:", error);
                setError("Failed to fetch meeting details. Please try again.");

                // Reset all loading states in case of error
                setIsFetchingMeetingId(false);
                setIsFetchingTranscript(false);
                setIsGeneratingNotes(false);
                setIsGeneratingRecap(false);
                setIsGeneratingSummaries(false);
                setIsGeneratingNextSteps(false);
            }
        };

        fetchMeetingDetails();
    }, [selectedMeeting, isAuthenticated, accounts, saminutesMsalInstance]);

    const OpenSideBar = () => {
        setSideBarBloc(true);
    };

    const CloseSideBar = () => {
        setSideBarBloc(false);
    };

    const handleDownload = async (formattedText, filename) => {
        try {
            await downloadMeetingMinutes({
                meetingTitle,
                participants,
                quickRecap: formattedText.quickRecap,
                summaries: formattedText.summaries,
                nextSteps: formattedText.nextSteps,
                filename,
            });
        } catch (error) {
            console.error("Error downloading document:", error);
            setError(
                error.message || "Failed to download meeting minutes. Please try again."
            );
        }
    };

    const handleSendEmail = () => {
        if (participants.length === 0) {
            setError("No participants to email.");
            return;
        }

        const validEmails = participants
            .map((p) => p.email)
            .filter((email) => email && isValidEmail(email));

        if (validEmails.length === 0) {
            setError("No valid email addresses found for participants.");
            return;
        }

        const subject = `Meeting Minutes: ${meetingTitle}`;

        // Construct the body directly:
        let body = `**${meetingTitle}**
  
  **Attendees:**
  
  **Quick Recap:**
  ${quickRecap}
  
  ---
  **Summary:**
  
  ${summaries}
  
  ---
  **Next Steps:**
  ${nextSteps}`;

        // Encode subject and body
        let encodedSubject = encodeURIComponent(subject);
        let encodedBody = encodeURIComponent(body);

        // Replace %0A with %0D%0A for better compatibility with email clients
        encodedBody = encodedBody.replace(/%0A/g, '%0D%0A');

        const mailtoLink = `mailto:${validEmails.join(",")}?subject=${encodedSubject}&body=${encodedBody}`;

        console.log('Generated mailto link:', mailtoLink);

        // Open the user's default mail client
        window.location.href = mailtoLink;
    };










    const formatOutput = () => {
        return `**${meetingTitle}**

**Attendees:** ${participants.map((p) => p.name).join(", ")}

**Quick Recap:**
${quickRecap}

---
**Summary:**

${summaries}

---
**Next Steps:**
${nextSteps}`;
    };

    const copyContent = () => {
        const content = formatOutput();
        navigator.clipboard.writeText(content);
        alert("Content copied to clipboard!");
    };

    // Input handlers
    const handleRecapChange = (e) => {
        recapRef.current = e.currentTarget.innerText;
    };

    const handleSummariesChange = (e) => {
        summariesRef.current = e.currentTarget.innerText;
    };

    const handleNextStepsChange = (e) => {
        nextStepsRef.current = e.currentTarget.innerText;
    };

    const getEditableStyle = (isEditing) => {
        return isEditing
            ? {
                backgroundColor: "#fff",
                border: "1px solid #ccc",
                padding: "5px",
                borderRadius: "4px",
            }
            : {};
    };

    const sortedMeetings = [...meetings].sort(
        (a, b) => new Date(b.start.dateTime) - new Date(a.start.dateTime)
    );

    const meetingOptions = sortedMeetings.map((meeting) => {
        const meetingDate = new Date(meeting.start.dateTime).toLocaleString([], {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
        return {
            value: meeting.onlineMeeting.joinUrl,
            label: `${meeting.subject} - ${meetingDate}`,
        };
    });

    return (
        <PrivateLayout>
            <div id="main" style={{ marginLeft: showSideBarBlock ? "255px" : "0px" }}>
                <NavBar
                    displaySide={showSideBarBlock}
                    onDisplaySideOpen={OpenSideBar}
                    onDisplaySideClose={CloseSideBar}
                />
                <div className="chatBodySaminute">
                    <div className="containerSaminute">
                        <div className="headerSaminute">
                            <img
                                src={SaminutesIcon}
                                alt="Saminutes Icon"
                                className="iconSaminute"
                            />
                            <div>
                                <p className="descriptionSaminute">
                                    Select a previously held Microsoft Teams meeting and generate
                                    comprehensive meeting minutes effortlessly!
                                </p>
                            </div>
                        </div>

                        <Select
                            options={meetingOptions}
                            value={
                                selectedMeeting
                                    ? meetingOptions.find((opt) => opt.value === selectedMeeting)
                                    : null
                            }
                            onChange={(option) => {
                                const selectedJoinUrl = option?.value || "";
                                setSelectedMeeting(selectedJoinUrl);

                                const selectedMeetingObj = meetings.find(
                                    (m) => m.onlineMeeting.joinUrl === selectedJoinUrl
                                );

                                if (selectedMeetingObj) {
                                    setMeetingTitle(selectedMeetingObj.subject);
                                } else {
                                    setMeetingTitle("Meeting Title");
                                }

                                setSummaries("");
                                setNextSteps("");
                                setMeetingNotes("");
                                setQuickRecap("");
                                setError("");

                                setIsFetchingMeetingId(false);
                                setIsFetchingTranscript(false);
                                setIsGeneratingNotes(false);
                                setIsGeneratingRecap(false);
                                setIsGeneratingSummaries(false);
                                setIsGeneratingNextSteps(false);
                            }}
                            placeholder="Search and select a meeting..."
                            isClearable
                            styles={{
                                container: (base) => ({
                                    ...base,
                                    marginBottom: "10px",
                                }),
                            }}
                            aria-label="Search and select a meeting"
                        />

                        {error && (
                            <div className="errorSaminute" role="alert">
                                {error}
                            </div>
                        )}

                        {selectedMeeting && (
                            <div>
                                {(isFetchingMeetingId ||
                                    isFetchingTranscript ||
                                    isGeneratingNotes ||
                                    isGeneratingRecap ||
                                    isGeneratingSummaries ||
                                    isGeneratingNextSteps) && (
                                        <div className="loadingSaminute">
                                            <SpinnerSaminute />
                                            {isFetchingMeetingId && <p>Fetching Meeting ID...</p>}
                                            {isFetchingTranscript && <p>Fetching Transcript...</p>}
                                            {isGeneratingNotes && <p>Generating Meeting Notes...</p>}
                                            {isGeneratingRecap && <p>Generating Quick Recap...</p>}
                                            {isGeneratingSummaries && <p>Generating Summary...</p>}
                                            {isGeneratingNextSteps && <p>Generating Next Steps...</p>}
                                        </div>
                                    )}

                                {summaries && nextSteps && (
                                    <div
                                        className="outputContainerSaminute"
                                        style={{
                                            position: "relative",
                                            backgroundColor: "#f9f9f9",
                                            padding: "20px",
                                            borderRadius: "8px",
                                            color: "#333",
                                            border: "1px solid #ccc",
                                            boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
                                            marginTop: "20px",
                                        }}
                                    >
                                        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>Meeting Minutes</h2>

                                        {/* Copy Icon Button */}
                                        <button
                                            style={{
                                                position: "absolute",
                                                top: "10px",
                                                right: "10px",
                                                border: "none",
                                                background: "none",
                                                cursor: "pointer",
                                            }}
                                            onClick={copyContent}
                                            aria-label="Copy all content"
                                            title="Copy all content"
                                        >
                                            <img src={CopyIcon} alt="Copy icon" style={{ width: "24px", height: "24px" }} />
                                        </button>

                                        <div style={{ whiteSpace: "pre-wrap" }}>
                                            <div><strong>{meetingTitle}</strong></div>
                                            <div>
                                                <strong>Attendees:</strong> {participants.map((p) => p.name).join(", ")}
                                            </div>

                                            {/* Quick Recap Section */}
                                            <div style={{ marginTop: "10px", position: "relative" }}>
                                                <strong>Quick Recap:</strong>
                                                {!isEditingRecap && (
                                                    <button
                                                        onClick={() => {
                                                            recapRef.current = quickRecap;
                                                            setIsEditingRecap(true);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Edit Quick Recap"
                                                    >
                                                        <img src={EditIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                {isEditingRecap && (
                                                    <button
                                                        onClick={() => {
                                                            setQuickRecap(recapRef.current);
                                                            setIsEditingRecap(false);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Save Quick Recap"
                                                    >
                                                        <img src={SaveIcon} alt="Save" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                <div
                                                    contentEditable={isEditingRecap}
                                                    suppressContentEditableWarning={true}
                                                    style={{
                                                        ...getEditableStyle(isEditingRecap),
                                                        padding: "5px 0",
                                                        marginTop: "5px"
                                                    }}
                                                    onInput={handleRecapChange}
                                                >
                                                    {quickRecap}
                                                </div>
                                            </div>

                                            {/* Summary Section */}
                                            <div style={{ marginTop: "10px", position: "relative" }}>
                                                <hr />
                                                <strong>Summary:</strong>
                                                {!isEditingSummaries && (
                                                    <button
                                                        onClick={() => {
                                                            summariesRef.current = summaries;
                                                            setIsEditingSummaries(true);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Edit Summary"
                                                    >
                                                        <img src={EditIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                {isEditingSummaries && (
                                                    <button
                                                        onClick={() => {
                                                            setSummaries(summariesRef.current);
                                                            setIsEditingSummaries(false);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Save Summary"
                                                    >
                                                        <img src={SaveIcon} alt="Save" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                <div
                                                    contentEditable={isEditingSummaries}
                                                    suppressContentEditableWarning={true}
                                                    style={{
                                                        ...getEditableStyle(isEditingSummaries),
                                                        padding: "5px 0",
                                                        marginTop: "5px"
                                                    }}
                                                    onInput={handleSummariesChange}
                                                >
                                                    {summaries}
                                                </div>
                                            </div>

                                            {/* Next Steps Section */}
                                            <div style={{ marginTop: "10px", position: "relative" }}>
                                                <hr />
                                                <strong>Next Steps:</strong>
                                                {!isEditingNextSteps && (
                                                    <button
                                                        onClick={() => {
                                                            nextStepsRef.current = nextSteps;
                                                            setIsEditingNextSteps(true);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Edit Next Steps"
                                                    >
                                                        <img src={EditIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                {isEditingNextSteps && (
                                                    <button
                                                        onClick={() => {
                                                            setNextSteps(nextStepsRef.current);
                                                            setIsEditingNextSteps(false);
                                                        }}
                                                        style={{
                                                            border: "none",
                                                            background: "none",
                                                            cursor: "pointer",
                                                            marginLeft: "10px"
                                                        }}
                                                        title="Save Next Steps"
                                                    >
                                                        <img src={SaveIcon} alt="Save" style={{ width: "16px", height: "16px" }} />
                                                    </button>
                                                )}
                                                <div
                                                    contentEditable={isEditingNextSteps}
                                                    suppressContentEditableWarning={true}
                                                    style={{
                                                        ...getEditableStyle(isEditingNextSteps),
                                                        padding: "5px 0",
                                                        marginTop: "5px"
                                                    }}
                                                    onInput={handleNextStepsChange}
                                                >
                                                    {nextSteps}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="buttonContainerSaminute" style={{ marginTop: "20px" }}>
                                            <button
                                                className="downloadButtonSaminute"
                                                onClick={() =>
                                                    handleDownload({ quickRecap, summaries, nextSteps }, "MeetingMinutes")
                                                }
                                                aria-label="Download Meeting Minutes"
                                                style={{ border: "none", background: "none", cursor: "pointer" }}
                                            >
                                                <img src={WordIcon} alt="Download Meeting Minutes" style={{ width: "24px", height: "24px" }} />
                                            </button>
                                            <button
                                                className="emailButtonSaminute"
                                                onClick={handleSendEmail}
                                                aria-label="Open mail client to email Meeting Minutes"
                                                style={{ border: "none", background: "none", cursor: "pointer" }}
                                            >
                                                <img src={EmailIcon} alt="Email Meeting Minutes" style={{ width: "24px", height: "24px" }} />
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </PrivateLayout>
    );
}

function Saminutes() {
    return (
        <SaminutesMsalProvider>
            <SaminutesContent />
        </SaminutesMsalProvider>
    );
}

export default Saminutes;

function isValidEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
}
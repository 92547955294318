// generateMeetingNotes.js

import axios from 'axios';

export const generateMeetingNotes = async (transcript) => {
  try {
    // Load environment variables
    const endpoint = `${process.env.REACT_APP_OPENAI_ENDPOINT}/openai/deployments/gpt-4o/chat/completions?api-version=2024-02-15-preview`;
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY; // Securely loaded from .env
    const deploymentName = 'gpt-4o'; // Replace with your actual deployment name

    // Split the transcript into chunks if necessary
    const MAX_TOKENS_PER_CHUNK = 3000;
    const transcriptChunks = splitTranscript(transcript, MAX_TOKENS_PER_CHUNK);

    let partialSummaries = [];

    for (const chunk of transcriptChunks) {
      const messages = [
        {
          role: 'system',
          content: `You are an AI assistant specialized in generating detailed and accurate meeting summaries. Analyze the following meeting transcript excerpt and produce the following sections in the same language of transcript:

- **Meeting Notes**: Provide a comprehensive summary of the topics discussed, using bullet points for clarity.
- **Next Steps**: Outline all action items or decisions made as next steps, presented as bullet points.

Please present the information under the headings "**Meeting Notes**," and "**Next Steps**." Ensure that the summaries are extensive and accurate.`,
        },
        {
          role: 'user',
          content: chunk,
        },
      ];

      try {
        const response = await axios.post(
          endpoint,
          {
            messages: messages,
            max_tokens: 2000,
            temperature: 0.2,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-key': apiKey,
            },
          }
        );

        const partialSummary = response.data.choices[0].message.content.trim();
        partialSummaries.push(partialSummary);
      } catch (error) {
        console.error(`Error processing chunk: ${error}`);
        continue;
      }
    }

    // Combine partial summaries
    const combinedSummary = partialSummaries.join('\n\n');
    return combinedSummary;
  } catch (error) {
    console.error('Error generating meeting notes:', error);
    throw error;
  }
};

// Helper function to split the transcript into chunks
const splitTranscript = (text, maxTokens) => {
  const chunks = [];
  let currentChunk = '';

  const words = text.split(' ');
  for (const word of words) {
    if ((currentChunk + ' ' + word).split(' ').length > maxTokens) {
      chunks.push(currentChunk);
      currentChunk = word;
    } else {
      currentChunk += ' ' + word;
    }
  }
  if (currentChunk) {
    chunks.push(currentChunk);
  }
  return chunks;
};

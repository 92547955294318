import React from 'react'
import { useAppSelector } from '../../../store/reducers/store'
import SearchResultItem from './SearchResultItem';

const SearchResults = () => {
    const results = useAppSelector(state => state.search.results);
    const loading = useAppSelector(state => state.search.searching);
    return (
        <div className='searchResults'>
            {loading ?
                <>Searching...</>
                :
                <>
                    {results.length > 0 ?
                        results.map((r) => <SearchResultItem result={r} key={`search-result-${r.document.chunk_id}`} />)
                        :
                        <p>No results for now</p>
                    }
                </>
            }
        </div>
    )
}

export default SearchResults
import React, { useState } from 'react';
// import copy from '../../../assets/images/copy.svg';
// import check from '../../../assets/images/check_small.svg';
import download from '../../../assets/images/download.svg';

const TranslatedFile = ({ file }) => {
    const [copied, setCopied] = useState(false);

    const copyFileTranslation = () => {
        navigator.clipboard.writeText(file.content);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 3000);
    };

    return (
        <div className='document'>
            <div className='content'>
                <div className='filename'>{file.name}</div>
                <div className='filesize'>{(file.size / 1000000).toFixed(1)} Mo</div>
            </div>
            <div className='remove'>
                {/* <img src={copied ? check : copy} onClick={() => copyFileTranslation()} alt="Copy" /> */}
                <a href={`data:@file/vnd.openxmlformats-officedocument.wordprocessingml.document;base64,${file.content}`} target="_blank" download={file.name}><img src={download} alt="Download" /> </a>
            </div>
        </div>
    )
}

export default TranslatedFile
import Chat from '../pages/Chat';
import Disclaimer from '../pages/Disclaimer';
import Home from '../pages/Home';
import RedirectLoginPage from '../pages/redirectLogin';
import Translate from '../pages/Translate';
import Documents from '../pages/Documents';
import chat from '../assets/images/sidebar/chat_icon.svg'
import translate from '../assets/images/sidebar/translate_icon.svg'
import search from '../assets/images/sidebar/search_icon.svg'
import prompts from '../assets/images/sidebar/prompts_icon.svg'
import meetingsminutes from '../assets/images/sidebar/meetingsminutes_icon.svg'
import Login from '../pages/Login';
import Search from '../pages/Search';
import Prompts from '../pages/Prompts';
import MeetingMinutes from '../pages/MeetingsMinutes';

/*
    Define React routes + Menu
    path: Relative path of the page (ex 'chat')
    title: Title of the menu (if applies)
    element: React element of your page
    isMenu: Show a menu to link to your page
    icon: Icon to use in the menu
*/
export const RoutesConstant = [
    { path: "", title: "Login", element: <Login />, isMenu: false, icon: undefined },
    { path: "disclaimer", title: "Disclaimer", element: <Disclaimer />, isMenu: false, icon: undefined },
    { path: "home", title: "Chat", element: <Home />, isMenu: true, icon: chat },
    { path: "chat", title: "Old chat", element: <Chat />, isMenu: false, icon: undefined },
    { path: "search", title: "Search", element: <Search />, isMenu: false, icon: search },
    { path: "translate", title: "Translation", element: <Translate />, isMenu: true, icon: translate },
    { path: "prompts", title: "Prompts", element: <Prompts />, isMenu: false, icon: prompts },
    { path: "documents", title: "Login", element: <Documents />, isMenu: false, icon: undefined },
    { path: "minutes", title: "Meetings minutes", element: <MeetingMinutes />, isMenu: true, icon: meetingsminutes },
    { path: "redirectLogin", title: "Redirect Login", element: <RedirectLoginPage />, isMenu: false, icon: undefined }
]
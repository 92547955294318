// generateNextSteps.js

import axios from 'axios';


export const generateNextSteps = async (nextStepsInput) => {
  try {
    // Load environment variables
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const endpointBase = process.env.REACT_APP_OPENAI_ENDPOINT;
    const deploymentName = 'gpt-4o'; // Replace with your actual deployment name

    if (!apiKey || !endpointBase) {
      throw new Error('API key or endpoint is not defined. Check your environment variables.');
    }

    const endpoint = `${endpointBase}/openai/deployments/${deploymentName}/chat/completions?api-version=2024-02-15-preview`;

    // Define the maximum number of tokens for chunking
    const MAX_TOKENS_PER_CHUNK = 4000; // Adjust based on model capacity

    // Split the next steps into manageable chunks
    const stepsChunks = splitText(nextStepsInput, MAX_TOKENS_PER_CHUNK);

    let professionalNextSteps = [];

    for (let idx = 0; idx < stepsChunks.length; idx++) {
      const chunk = stepsChunks[idx];
      const stepsPrompt = [
        {
          role: 'system',
          content: `You are an AI assistant tasked with summarizing next steps from a meeting into
professional and objective bullet points in the SAME language of input:
Follow the following:
- Never use labels like "Next Steps". Instead, directly provide the bullets and NOTHING ELSE.
- Extract only actionable items, avoiding generic or redundant details.
- Present the next steps in clear, concise, and professional language.
- Use clear objective bullet points

Ensure the output is focused and actionable.`,
        },
        {
          role: 'user',
          content: chunk,
        },
      ];

      try {
        console.log(`Processing chunk ${idx + 1}/${stepsChunks.length}...`);

        const response = await axios.post(
          endpoint,
          {
            messages: stepsPrompt,
            max_tokens: 500, // Short output for bullet points
            temperature: 0.3, // Lower temperature for consistent results
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-key': apiKey,
            },
          }
        );

        const chunkBullets = response.data.choices[0].message.content.trim();

        // Validate the content to ensure it adheres to the structure and excludes incomplete or irrelevant information
        if (chunkBullets && !chunkBullets.includes('...')) {
          professionalNextSteps.push(chunkBullets);
        } else {
          console.warn(`Warning: Chunk ${idx + 1} contains incomplete or irrelevant content and will be skipped.`);
        }
      } catch (error) {
        console.error(`Error processing chunk ${idx + 1}:`, error);
        continue;
      }
    }

    // Combine all bullet points into a single string
    const finalNextSteps = professionalNextSteps.join('\n').trim();
    return finalNextSteps;
  } catch (error) {
    console.error('Error generating next steps:', error);
    throw error;
  }
};


const splitText = (text, maxTokens) => {
  const chunks = [];
  let currentChunk = '';

  // Split by sentences to maintain context
  const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [text];
  for (const sentence of sentences) {
    const sentenceLength = sentence.split(' ').length;
    const currentChunkLength = currentChunk.split(' ').length;

    if (currentChunkLength + sentenceLength > maxTokens) {
      if (currentChunk) {
        chunks.push(currentChunk.trim());
      }
      currentChunk = sentence;
    } else {
      currentChunk += ' ' + sentence;
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk.trim());
  }

  return chunks;
};

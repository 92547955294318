import { useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react'
import config from "../../../config.json"
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store';
import { loadUserChats, newChat, saveChat } from '../../../store/actions/chat';
import ChatListItem from './ChatListItem';

const ChatList = () => {
    const { instance } = useMsal();
    const [groupedConversations, setGroupedConversations] = useState({});
    const dispatch = useAppDispatch();
    const history = useAppSelector(state => state.chat.history);
    const selectedConversation = useAppSelector(state => state.chat.selectedConversation);
    const getConversations = useAppSelector(state => state.chat.chatConversations);
    const conversationToSelect = getConversations ? getConversations[selectedConversation] : undefined;
    const chatConversations = React.useMemo(() => {
        if (getConversations) {
            return getConversations.slice().sort((a, b) => new Date(b.Timestamp) - new Date(a.Timestamp));
        }
        return getConversations;
    }, [getConversations]);

    const onSave = (e) => {
        e.preventDefault();
        e.stopPropagation();
        dispatch(saveChat({ account: instance.getActiveAccount(), chatHistory: history }));
    }

    const onNewChat = (e) => {
        e.preventDefault();
        dispatch(newChat({ account: instance.getActiveAccount() }));
    }

    useEffect(() => {
        if (chatConversations === undefined) {
            dispatch(loadUserChats(instance.getActiveAccount()));
        }
    }, [chatConversations, instance, dispatch]);

    useEffect(() => {
        if (chatConversations) {

            const calculateTimeIntervals = () => {
                const today = new Date();
                const yesterday = new Date(today);
                yesterday.setDate(today.getDate() - 1);
                // Calculate other intervals (Previous 7 days, etc.)

                const groupedData = {};
                chatConversations.forEach(conversation => {
                    const conversationDate = new Date(conversation.Timestamp);

                    // Determine the time interval based on conversationDate
                    const interval = determineTimeInterval(conversationDate);

                    if (!groupedData[interval]) {
                        groupedData[interval] = [];
                    }
                    groupedData[interval].push(conversation);
                });

                setGroupedConversations(groupedData);
            };

            calculateTimeIntervals();
        }
    }, [chatConversations]);

    const determineTimeInterval = (date) => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        yesterday.setHours(0, 0, 0, 0);

        // Assuming date is a Date object
        const dateStartOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        dateStartOfDay.setHours(0, 0, 0, 0);

        if (dateStartOfDay.getTime() === today.getTime()) {
            return 'Today';
        } else if (dateStartOfDay.getTime() === yesterday.getTime()) {
            return 'Yesterday';
        } else if (dateStartOfDay >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)) {
            return 'Previous 7 days';
        } else if (dateStartOfDay >= new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30)) {
            return 'Previous 30 days';
        } else {
            // Handle other intervals like months or years
            const month = date.getMonth() + 1; // Months are zero-indexed
            const year = date.getFullYear();
            return `${month}/${year}`;
        }
    };

    return (
        <div className='chats'>
            <div className="recentChats">
                <ul>

                    {selectedConversation === undefined ?
                        <li className="unsaved">New conversation {history.length > 0 && !config.features.autosave && <button className='link' onClick={onSave}>Save chat</button>}</li>
                        :
                        <li className="newChat">
                            <button className='link' id="side_newChat" onClick={onNewChat}> New Chat</button>
                        </li>
                    }
                    {chatConversations === undefined ?
                        <>Loading...</>
                        :
                        <>
                            {Object.entries(groupedConversations).map(([interval, conversations]) => (
                                <div key={interval}>
                                    <h3>{interval}</h3>
                                    <ul>
                                        {conversations.map((conversation, index) => (
                                            <ChatListItem
                                                key={`${interval}-${conversation.ConversationID}`} // Ensures unique keys
                                                item={conversation}
                                                selected={conversation.ConversationID === conversationToSelect?.ConversationID}
                                                index={index}
                                                onSave={onSave}
                                            />
                                        ))}
                                    </ul>
                                </div>
                            ))}

                        </>

                    }
                </ul>
            </div>
        </div>
    )
}

export default ChatList
import React, { useMemo, useState } from "react";
import logosmall from '../.././assets/images/logosmall.svg';
import SideBar from './SideBar.js';
import { useAppDispatch } from "../../store/reducers/store.js";
import { newChat } from "../../store/actions/chat.js";
import config from "../../config.json"
import { useMsal } from "@azure/msal-react";

const darkModeStorage = JSON.parse(localStorage.getItem('darkMode')) || false;
let sidebar; let add;
if (darkModeStorage) {
    sidebar = require('../.././assets/images/sidebar_dark.svg').default;
    add = require('../.././assets/images/addsidebar_dark.svg').default;
} else {
    sidebar = require('../.././assets/images/sidebar.svg').default;
    add = require('../.././assets/images/add.svg').default;
}

const NavBar = ({ displaySide, onDisplaySideOpen, onDisplaySideClose, customActions, menuContent }) => {
    const dispatch = useAppDispatch();

    const [showSideBarBlock, setSideBarBloc] = useState(displaySide);

    const AdjustSideBar = () => {
        setSideBarBloc(false);
        onDisplaySideClose();
    };

    const OpenSideBar = (ev) => {
        ev.preventDefault();
        setSideBarBloc(true);
        onDisplaySideOpen();
    };
    const onNewChat = (ev) => {
        ev.preventDefault();
        dispatch(newChat({ account: instance.getActiveAccount() }));
    }

    const { instance } = useMsal();
    const initial = useMemo(() => {
        return instance.getActiveAccount().username.charAt(0).toLocaleUpperCase();
    }, [instance])
    const handleLogoutClick = (ev) => {
        ev.preventDefault();
        instance.logoutRedirect()
            .then(function (response) { window.location.href = "/" })
            .catch((error) => console.error(error))
    }

    const [isLogOutVisible, setIsLogOutVisible] = useState(false);
    const handleLogoutVisibleClick = (ev) => {
        ev.preventDefault();
        setIsLogOutVisible(!isLogOutVisible);
    };

    // const handleClickOutside = (event) => {
    //     if (isLogOutVisible && !event.target.closest('.nav-item')) {
    //         setIsLogOutVisible(false);
    //     }
    // };

    return (
        <>

            {config.features.sidebar && <SideBar displaySide={showSideBarBlock} onDisplaySideChange={AdjustSideBar} menuContent={menuContent} />}
            <nav className="navbar navbar-expand-lg navbar-light">
                <div className="row">
                    <div className="col-md-6">
                        <div className="leftNav">
                            <div className="navActions">
                                {config.features.sidebar && <button className="link nav-action" id="sideBar" onClick={OpenSideBar} style={{ display: showSideBarBlock ? 'none' : 'inline-block' }} >
                                    <img src={sidebar} alt="Sidebar" />
                                </button>}

                                {config.features.newChat &&
                                    <button className="link nav-action" onClick={onNewChat} id="main_newChat" style={{ display: showSideBarBlock ? 'none' : 'inline-block' }}>
                                        <img src={add} alt="Add" />
                                    </button>}
                                <img src={logosmall} alt="Logo" />
                                <p> {config.homePage.text.appName} </p>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="rightNav">
                            {customActions && customActions}
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <button className="link nav-link" href="#" id="initialButton" onClick={handleLogoutVisibleClick}>
                                        <span className="userCharacter"> {initial} </span>
                                    </button>
                                    {isLogOutVisible && (
                                        <div className="logOut">
                                            <button className="link" onClick={handleLogoutClick}> {config.homePage.text.logout} </button>
                                        </div>
                                    )}
                                </li>
                            </ul>

                        </div>
                    </div>
                </div>
            </nav>
        </>
    );
}

export default NavBar;

// generateMajorTopicSummaries.js

import axios from "axios";

export const generateSummaries = async (meetingNotes) => {
  try {
    // Load environment variables
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const endpointBase = process.env.REACT_APP_OPENAI_ENDPOINT;
    const deploymentName = "gpt-4o"; // Replace with your actual deployment name

    if (!apiKey || !endpointBase) {
      throw new Error(
        "API key or endpoint is not defined. Check your environment variables."
      );
    }

    const endpoint = `${endpointBase}/openai/deployments/${deploymentName}/chat/completions?api-version=2024-02-15-preview`;

    // Define the token limit for chunking
    const MAX_TOKENS_PER_CHUNK = 4000; // Adjust based on model capacity

    // Split the meeting notes into chunks
    const notesChunks = splitText(meetingNotes, MAX_TOKENS_PER_CHUNK);

    // Placeholder for chunk summaries
    let topicSummaries = [];

    for (let idx = 0; idx < notesChunks.length; idx++) {
      const chunk = notesChunks[idx];

      // Define the prompt for generating major topic summaries
      const chunkPrompt = [
        {
          role: "system",
          content: `You are an AI assistant tasked with summarizing meeting notes.
Your task is to generate a structured summary following this format:

1. **Title**: Provide a clear and descriptive title for each major topic.
2. **Short Paragraph**: Write a professional, concise paragraph explaining the topic in factual terms.

STRICT INSTRUCTIONS:
- DO NOT include "Title:" or any other labels. Simply write the title and the paragraph.
- DO NOT include next steps, action items, or speculative statements in the paragraphs.
- ONLY output the title and the paragraph under it. NOTHING ELSE.
- DO NOT include incomplete information (e.g., "to read the..." or incomplete sentences).
- Ensure the paragraph is concise, factual, and includes only relevant details.
- Exclude filler content, repeated phrases, or subjective commentary.
- The output must be in the same language as the provided meeting notes.`,
        },
        {
          role: "user",
          content: chunk,
        },
      ];

      try {
        console.log(`Processing chunk ${idx + 1}/${notesChunks.length}...`);

        const response = await axios.post(
          endpoint,
          {
            messages: chunkPrompt,
            max_tokens: 2000, // Adjust to allow for detailed topic summaries
            temperature: 0.2, // Low temperature for stricter adherence to the prompt
          },
          {
            headers: {
              "Content-Type": "application/json",
              "api-key": apiKey,
            },
          }
        );

        const topicSummary = response.data.choices[0].message.content.trim();

        // Validate the content to ensure it adheres to the structure and excludes incomplete or irrelevant information
        if (topicSummary && !topicSummary.includes("...")) {
          topicSummaries.push(topicSummary);
        } else {
          console.warn(
            `Warning: Chunk ${
              idx + 1
            } contains incomplete or irrelevant content and will be skipped.`
          );
        }
      } catch (error) {
        console.error(`Error processing chunk ${idx + 1}:`, error);
        continue;
      }
    }

    // Combine all topic summaries
    const finalSummary = topicSummaries.join("\n\n").trim();

    return finalSummary;
  } catch (error) {
    console.error("Error generating major topic summaries:", error);
    throw error;
  }
};

// Helper function to split text into chunks based on token limit
const splitText = (text, maxTokens) => {
  const chunks = [];
  let currentChunk = "";

  const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [text];
  for (const sentence of sentences) {
    const sentenceLength = sentence.split(" ").length;
    const currentChunkLength = currentChunk.split(" ").length;

    if (currentChunkLength + sentenceLength > maxTokens) {
      chunks.push(currentChunk);
      currentChunk = sentence;
    } else {
      currentChunk += " " + sentence;
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
};

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GPTService } from "../../services/GptService";


export const setLanguage = createAction('translate/setLanguage');
export const setLanguageSource = createAction('translate/setLanguageSource');
export const setFiles = createAction('translate/setFiles');
export const setText = createAction('translate/setText');

export const sendTranslation = createAsyncThunk('translate/sendTranslation',
    async (text, thunkAPI) => {
        const service = GPTService;
        const state = thunkAPI.getState();

        return await service.handleTanslation(text, state.translate.language, (message) => { });
    }
)

export const translateDocument = createAsyncThunk('translate/translateDocument',
    async (file, thunkAPI) => {
        const service = GPTService;
        const state = thunkAPI.getState();

        return await service.handleDocumentTranslation(file, state.translate.languageSource, state.translate.language, (message) => { });
    }
)
import React, { useRef, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../store/reducers/store";
import { setTemperature } from "../../store/actions/chat";
import config from "../../config.json"

const Settings = ({ display, onDisplayChange }) => {

    let KLogo;
    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
    // Dynamically import styles based on dark mode
    if (darkMode) {
        KLogo = require('../.././assets/images/Klogo_dark.svg').default;
    } else {
        KLogo = require('../.././assets/images/K_logo.svg').default;
    }

    const dispatch = useAppDispatch();
    const sliderRef = useRef(null);

    useEffect(() => {

        const handleEscape = (event) => {
            if (event.keyCode === 27) {
                onDisplayChange(false);
            }
        };

        if (display) {
            document.addEventListener('keydown', handleEscape);
        }

        return () => {
            document.removeEventListener('keydown', handleEscape);
        };
    }, [display, onDisplayChange]);



    const temperature = useAppSelector(state => state.chat.temperature);
    const settingsBarRef = useRef(null); // Ref for direct DOM access (if needed)
    const handleCancelClick = () => {
        dispatch(setTemperature(5));
        onDisplayChange();
    };

    const handleApplyClick = () => {
        onDisplayChange();
    };

    const handleSliderChange = (event) => {
        const newTemperature = parseInt(event.target.value)
        dispatch(setTemperature(newTemperature));


        const progress = (newTemperature / 10) * 100;
        sliderRef.current.style.background = `linear-gradient(to right, #5435BE ${progress}%, #ccc ${progress}%)`;
    };

    let position = (temperature * 10) + 2;
    if (temperature === 0) {
        position = 4;
    }
    else if (temperature === 1) {
        position = 13;
    }
    else if (temperature === 2) {
        position = 22;
    }
    else if (temperature < 5) {
        position = (temperature * 10) + 1;
    }
    else if (temperature === 5) {
        position = (temperature * 10);
    }
    else if (temperature === 7) {
        position = 68.5;
    }
    else if (temperature === 8) {
        position = 77.5;
    }
    else if (temperature === 9) {
        position = 87;
    }
    else if (temperature < 10) {
        position = (temperature * 10) - 1;
    }
    if (temperature === 10) {
        position = 96;
    }
    const finalPosition = `${position}%`;
    const backgroundHighlight = `linear-gradient(to right, #5435BE ${temperature * 10}%, #ccc ${temperature * 10}%)`;
    return (
        <div>
            {display && (
                <div id="settingsBarBlock" className="settingsbar" ref={settingsBarRef} >
                    <div className="sideBarContent">
                        <button className='link' onClick={handleCancelClick}>
                            <img src={KLogo} alt="Cancel" />
                        </button>
                        <h2> {config.homePage.text.settings.title}</h2>
                        <h1> {config.homePage.text.settings.windowTitle}</h1>
                        <p>
                            {config.homePage.text.settings.windowDescription}
                        </p>
                        <div className="rangeBar">
                            <div className="settings_slider">
                                <input
                                    type="range"
                                    min="0"
                                    max="10"
                                    value={temperature}
                                    onChange={handleSliderChange}
                                    id="tempratureSlider"
                                    ref={sliderRef}
                                    style={{ background: backgroundHighlight }}
                                />
                            </div>
                            <span className="minValue">0</span>
                            <span className="currentValue" id="tempratureValue" style={{ left: finalPosition }} >{temperature}</span>

                            <span className="maxValue">10</span>
                        </div>

                        {/* <div className="type-select">
                            <Box sx={{ minWidth: 250 }}>
                                <FormControl fullWidth>
                                    <FormLabel id="chat-search-type-label" htmlFor="chat-search-type">Search type</FormLabel>
                                    <Select
                                        labelId="chat-search-type-label"
                                        id="chat-search-type"
                                        value={searchType}
                                        label="Search type"
                                        onChange={handleChange}>
                                            {Constants.SearchType.map(s => (<MenuItem value={s}>{s}</MenuItem>))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </div> */}
                        <div className="actions">
                            <button className='link' id="apply_settings" onClick={handleApplyClick}>
                                {config.homePage.text.settings.windowApply}
                            </button>
                            <button className='link' id="cancel_settings" onClick={handleCancelClick}>
                                {config.homePage.text.settings.windowCancel}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>

    );
}

export default Settings;

import React, { useCallback, useRef, useState } from 'react'
import { setFiles, setLanguage, setLanguageSource, translateDocument } from '../../../store/actions/translate';
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store';
import { Utilities } from '../../../utils/Utilities';
import deleteIcon from '../../../assets/images/delete.svg';
import translating from '../../../assets/images/translating.svg';
import config from "../../../config.json"

const FileSelector = () => {
    const fileRef = useRef();
    const dispatch = useAppDispatch();
    const files = useAppSelector(state => state.translate.files);
    const currentLanguage = useAppSelector(state => state.translate.language);
    const currentLanguageSource = useAppSelector(state => state.translate.languageSource);
    const [translatingIndex, setTranslatingIndex] = useState(undefined);
    const [showDroppable, setShowDroppable] = useState(false);

    const onFileUploadClick = () => {
        fileRef.current.click();
    }
    const onFileAdded = (e) => {
        dispatch(setFiles([...e.target.files]));
        e.target.value = ""
    }
    const dragOverHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShowDroppable(true);
    }
    const dragLeaveHandler = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShowDroppable(false);
    }
    const onFileDropped = (ev) => {
        ev.preventDefault();
        ev.stopPropagation();
        setShowDroppable(false);
        const files = [];
        if (ev.dataTransfer.items) {
            [...ev.dataTransfer.items].forEach((item, i) => {
                if (item.kind === "file") {
                    const file = item.getAsFile();
                    files.push(file);
                }
            });
        } else {
            [...ev.dataTransfer.files].forEach((file, i) => {
                files.push(file);
            });
        }
        dispatch(setFiles(files));
    }
    const onFileRemove = (i) => {
        const updateFiles = [...files];
        updateFiles.splice(i, 1);
        dispatch(setFiles(updateFiles));
    }

    const onTranslateNext = useCallback(async (index) => {
        await dispatch(translateDocument(await Utilities.docToBase64(files[index])))
        const newIndex = index + 1;
        if (newIndex >= files.length) {
            setTranslatingIndex(undefined);
        } else {
            setTranslatingIndex(newIndex);
            onTranslateNext(newIndex);
        }
    }, [dispatch, files]);
    const onTranslate = () => {
        setTranslatingIndex(0);
        onTranslateNext(0)
    }

    const onLanguageChanged = (e) => {
        dispatch(setLanguage(e.currentTarget.value));
    }
    const onLanguageSourceChanged = (e) => {
        dispatch(setLanguageSource(e.currentTarget.value));
    }

    const languages = config.features.translation.languages;
    return (<div className='documents'>
        <div className='title'>Document translator</div>
        <div className='subtitle'>Translate your word documents.</div>
        <div className='limit-message'>Be aware that, the testing phase limits the translation process. To work, the document should not have more than 3 pages.</div>
        {files.length > 0 ?
            <div className={`filesList ${showDroppable ? 'droppable' : ''}`} onDrop={onFileDropped} onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler}>
                {files.map((f, i) => (<div className='file' key={`file-${i}`}>
                    <div className='content'>
                        <div className='filename'>{f.name}</div>
                        <div className='filesize'>{(f.size / 1000000).toFixed(1)} Mo</div>
                    </div>
                    <div className='remove'>
                        {translatingIndex !== undefined ?
                            <>
                                {(translatingIndex === i) ?
                                    <img src={translating} alt="Translating document" />
                                    :
                                    <></>}
                            </>
                            :
                            <img src={deleteIcon} onClick={() => onFileRemove(i)} alt="Delete file" />
                        }
                    </div>
                </div>))}
            </div>
            :
            <div className={`dropZone ${showDroppable ? 'droppable' : ''}`} onClick={onFileUploadClick} onDrop={onFileDropped} onDragOver={dragOverHandler} onDragLeave={dragLeaveHandler}>
                <div>Drag and drop your documents here</div>
            </div>
        }
        <input type='file' ref={fileRef} onChange={onFileAdded} multiple />
        <div className='buttonsZone'>
            <div>From:</div>
            <select onChange={onLanguageSourceChanged} value={currentLanguageSource}>
                {languages.map(l => (<option value={l.key} key={`language-${l.key}`}>{l.value}</option>))}
            </select>
            <div>to</div>
            <select onChange={onLanguageChanged} value={currentLanguage}>
                {languages.map(l => (<option value={l.key} key={`language-${l.key}`}>{l.value}</option>))}
            </select>
            <button disabled={files.length === 0 || translatingIndex !== undefined} onClick={onTranslate}>Translate</button>
        </div>
    </div>
    )
}

export default FileSelector
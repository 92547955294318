import PublicLayout from "./../layouts/publicLayout";
import { useMsal, UnauthenticatedTemplate } from "@azure/msal-react";
import CircularProgress from '@mui/material/CircularProgress';
import { Navigate, useNavigate } from "react-router-dom";
import React, { useEffect } from 'react';
import { GPTService } from "../services/GptService";
import { useAppDispatch } from "../store/reducers/store";
import { setHasConsented } from "../store/actions/chat";

function RedirectLoginPage() {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const ensureConsent = async () => {
    const hasConsented = await GPTService.getHasConsented(accounts[0].localAccountId);
    if (hasConsented) {
      dispatch(setHasConsented(true));
      navigate("/home");
    } else {
      navigate("/disclaimer");
    }
  }

  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
    ensureConsent();
  }
  useEffect(() => {
  }, [])

  return (
    <PublicLayout>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <div style={{ alignItems: "center", display: "flex", justifyContent: "center", height: "10vh", width: "100vw" }}>
        <CircularProgress color="info" />
        <span style={{ justifyContent: "center", position: "fixed", top: "55%" }}>Loading</span>
      </div>
    </PublicLayout>
  );
}

export default RedirectLoginPage;

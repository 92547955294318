import { createReducer } from "@reduxjs/toolkit";
import { abortChat, addFiles, clearFiles, deleteConversation, editChat, loadUserChats, newChat, regenerate, removeFile, renameConversation, saveChat, selectConversation, sendChat, setGPTVersion, setHasConsented, setQuestion, setSearchTarget, setSearchType, setTemperature, setToken, setUser, updateChatStream } from "../actions/chat";
import { Constants } from "../../utils/Constants";
import { Utilities } from "../../utils/Utilities";
import { GPTService } from "../../services/GptService";
import config from "../../config.json"

/** 
 * Chat REDUCER
 * 
 * Store shared info for the chat to work between objects
*/

const initialState = {
    user: undefined,
    token: undefined,
    tokenExpires: undefined,
    hasConsented: undefined,
    gptVersion: Constants.GPTVersion[4],
    temperature: 0,
    searchTarget: Constants.SearchTarget.Chat,
    searchType: Constants.SearchType[0],
    nbOfDocuments: 2,
    history: [],
    fetching: false,
    question: "",
    files: [],
    chatConversations: undefined,
    selectedConversation: undefined
}

const chatReducer = createReducer(initialState, (builder) => {
    builder.addCase(setUser, (state, action) => {
        state.user = action.payload;
    });

    builder.addCase(setToken, (state, action) => {
        state.token = action.payload.token;
        state.tokenExpires = action.payload.expiresOn;
    })

    builder.addCase(setHasConsented, (state, action) => {
        state.hasConsented = action.payload;
    });

    builder.addCase(setGPTVersion, (state, action) => {
        state.gptVersion = action.payload;
    });

    builder.addCase(setTemperature, (state, action) => {
        state.temperature = (isNaN(action.payload) ? 5 : action.payload);
    });

    builder.addCase(setSearchTarget, (state, action) => {
        state.searchTarget = action.payload;
    });
    builder.addCase(setSearchType, (state, action) => {
        state.searchType = action.payload;
    })

    builder.addCase(setQuestion, (state, action) => {
        state.question = action.payload;
    });

    builder.addCase(addFiles, (state, action) => {
        state.files = action.payload;
    });

    builder.addCase(removeFile, (state, action) => {
        const newFiles = [...state.files];
        newFiles.splice(action.payload, 1);
        state.files = newFiles;
    });
    builder.addCase(clearFiles, (state) => {
        state.files = [];
    });

    /** Handling Chat API request */
    builder.addCase(sendChat.pending, (state, action) => {
        state.fetching = true;
        state.question = "";
        state.history.push({ question: action.meta.arg, answer: undefined });
    })

    builder.addCase(sendChat.fulfilled, (state, action) => {
        state.fetching = false;
        const data = action.payload;
        state.history[state.history.length - 1].answer = data.answer;
        state.history[state.history.length - 1].answer_html = Utilities.stringFromBinary(data.answer_html);
        if (data.references) {
            state.history[state.history.length - 1].references = [...data.references];
        } else {
            state.history[state.history.length - 1].references = [];
        }
        if (data.suggested_questions) {
            state.history[state.history.length - 1].suggested_questions = [...data.suggested_questions];
        } else {
            state.history[state.history.length - 1].suggested_questions = [];
        }
        state.history[state.history.length - 1].deployement = data.deployement;
        state.history[state.history.length - 1].questions_generated = data.questions_generated;
        state.history[state.history.length - 1].chat_history = [...data.chat_history];
        state.history[state.history.length - 1].gpt_model = data.gpt_model;
        state.history[state.history.length - 1].prompt = data.prompt;
        state.history[state.history.length - 1].search_type = data.search_type;
        state.history[state.history.length - 1].temperature = data.temperature;
        state.history[state.history.length - 1].chunks = data.chunks;
    })
    builder.addCase(sendChat.rejected, (state, action) => {
        state.fetching = false;
        if (state.history[state.history.length - 1] === undefined) {
            return;
        }
        if (action.error.code === "ERR_CANCELED") {
            state.history[state.history.length - 1].answer = "You have interrupted the Answer";
            state.history[state.history.length - 1].answer_html = "<p>You have interrupted the Answer</p>";
        } else {
            state.history[state.history.length - 1].answer = "There was an error, please try again";
            state.history[state.history.length - 1].answer_html = "<p>There was an error, please try again</p>";
        }
    });
    builder.addCase(updateChatStream, (state, action) => {
        const index = action.payload.messageindex !== undefined ? action.payload.message.index : (state.history.length > 0 ? state.history.length - 1 : 0);
        if (index > -1) {
            state.history[index].answer = action.payload.message;
            state.history[index].answer_html = action.payload.message;
        }

    })
    /** Handling Chat API request on regenerate */
    builder.addCase(regenerate.pending, (state, action) => {
        state.fetching = true;
        const new_history = [...state.history];
        new_history.push({ question: action.meta.arg.question.question, answer: undefined });
        state.history = new_history;
    })

    builder.addCase(regenerate.fulfilled, (state, action) => {
        state.fetching = false;
        const data = action.payload;
        const index = state.history.length - 1;
        state.history[index].answer = data.answer;
        state.history[index].answer_html = Utilities.stringFromBinary(data.answer_html);
        if (data.references) {
            state.history[index].references = [...data.references];
        } else {
            state.history[index].references = [];
        }
        if (data.suggestedQuestions) {
            state.history[index].suggestedQuestions = [...data.suggestedQuestions];
        } else {
            state.history[index].suggestedQuestions = [];
        }
        state.history[index].deployement = data.deployement;
        state.history[index].questions_generated = data.questions_generated;
        state.history[index].chat_history = [...data.chat_history];
        state.history[index].gpt_model = data.gpt_model;
        state.history[index].prompt = data.prompt;
        state.history[index].search_type = data.search_type;
        state.history[index].temperature = data.temperature;
        state.history[state.history.length - 1].chunks = data.chunks;
    })
    builder.addCase(regenerate.rejected, (state, action) => {
        state.fetching = false;
        const index = state.history.length - 1;
        if (action.error.code === "ERR_CANCELED") {
            state.history[index].answer = "You have interrupted the Answer";
            state.history[index].answer_html = "<p>You have interrupted the Answer</p>";
        } else {
            state.history[index].answer = "There was an error, please try again";
            state.history[index].answer_html = "<p>There was an error, please try again</p>";
        }
    });

    /** Handling Chat API request on edit */
    builder.addCase(editChat.pending, (state, action) => {
        state.fetching = true;
        const new_history = state.history.slice(0, action.meta.arg.index);
        new_history.push({ question: action.meta.arg.question, answer: undefined });
        state.history = new_history;
    })

    builder.addCase(editChat.fulfilled, (state, action) => {
        state.fetching = false;
        const data = action.payload;
        state.history[action.meta.arg.index].answer = data.answer;
        state.history[action.meta.arg.index].answer_html = Utilities.stringFromBinary(data.answer_html);
        if (data.references) {
            state.history[action.meta.arg.index].references = [...data.references];
        } else {
            state.history[action.meta.arg.index].references = [];
        }
        if (data.suggestedQuestions) {
            state.history[action.meta.arg.index].suggestedQuestions = [...data.suggestedQuestions];
        } else {
            state.history[action.meta.arg.index].suggestedQuestions = [];
        }
        state.history[action.meta.arg.index].deployement = data.deployement;
        state.history[action.meta.arg.index].questions_generated = data.questions_generated;
        state.history[action.meta.arg.index].chat_history = [...data.chat_history];
        state.history[action.meta.arg.index].gpt_model = data.gpt_model;
        state.history[action.meta.arg.index].prompt = data.prompt;
        state.history[action.meta.arg.index].search_type = data.search_type;
        state.history[action.meta.arg.index].temperature = data.temperature;
        state.history[state.history.length - 1].chunks = data.chunks;
    })
    builder.addCase(editChat.rejected, (state, action) => {
        state.fetching = false;
        if (action.error.code === "ERR_CANCELED") {
            state.history[action.meta.arg.index].answer = "You have interrupted the Answer";
            state.history[action.meta.arg.index].answer_html = "<p>You have interrupted the Answer</p>";
        } else {
            state.history[action.meta.arg.index].answer = "There was an error, please try again";
            state.history[action.meta.arg.index].answer_html = "<p>There was an error, please try again</p>";
        }
    });
    builder.addCase(abortChat, () => {
        GPTService.cancelChat();
    })
    builder.addCase(loadUserChats.pending, (state) => {
        state.chatConversations = undefined;
    });
    builder.addCase(loadUserChats.fulfilled, (state, action) => {
        state.chatConversations = action.payload;

    });
    builder.addCase(saveChat.pending, (state) => {
    });
    builder.addCase(saveChat.fulfilled, (state, action) => {
        if (!config.features.newChat || action.payload === undefined) {
            return;
        }
        const newchats = state.chatConversations ? [...state.chatConversations] : []
        const exist = newchats.find(p => p.ConversationID === action.payload.ConversationID);
        if (exist === undefined) {
            newchats.unshift(action.payload);
            state.chatConversations = newchats;
            if (!action.meta.arg.noAutoSelect) {
                state.selectedConversation = 0;
            }
        }
    });
    builder.addCase(selectConversation.pending, (state, action) => {
        // state.history = [];
    });
    builder.addCase(selectConversation.fulfilled, (state, action) => {
        if (action.payload.ConversationID) {
            state.selectedConversation = state.chatConversations.findIndex(c => c.ConversationID === action.payload.ConversationID);
            state.history = action.payload.Messages.map(m => ({
                question: m.Question,
                answer: m.Answer,
                answer_html: m.AnswerHtml,
                answer_markdown: m.AnswerMarkdown,
                temperature: m.Temperature,
                deployement: m.Deployement,
                gpt_model: m.GptModel,
                references: m.References,
                suggestedQuestions: m.SuggestedQuestions,
                chat_history: m.ChatHistory,
                questions_generated: m.QuestionsGenerated
            }));
        } else {
            state.selectedConversation = undefined;
            state.history = [];
        }
    });
    builder.addCase(newChat, (state) => {
        state.history = [];
        state.selectedConversation = undefined;
    });

    builder.addCase(deleteConversation.fulfilled, (state, action) => {
        const conv = [...state.chatConversations];
        const index = conv.findIndex(c => c.ConversationID === action.meta.arg.conversationID);
        conv.splice(index, 1);
        state.chatConversations = conv;
        state.history = [];
        state.selectedConversation = undefined;
    });
    builder.addCase(renameConversation.fulfilled, (state, action) => {
        const conv = [...state.chatConversations];
        const index = conv.findIndex(c => c.ConversationID === action.meta.arg.conversationID);
        conv[index].ConversationTitle = action.meta.arg.title;
        state.chatConversations = conv;
    })
});

export default chatReducer;
import React from 'react'
import MultiSelectDropdown from '../common/MultiSelectDropdown'
import source_icon from '../../../assets/images/search/sources.svg'
import sharepoint_icon from '../../../assets/images/search/sharepoint.svg'
import outlookIcon from '../../../assets/images/search/outlookIcon.svg'
import glpiIcon from '../../../assets/images/search/glpiIcon.svg'
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store'
import { setIndex } from '../../../store/actions/search'

const SearchSources = () => {
    const index = useAppSelector(state => state.search.index);
    const dispatch = useAppDispatch();

    const onChange = (values) => {
        dispatch(setIndex(values));
    }

    return <MultiSelectDropdown label={"Sources"} icon={source_icon} selection={index} choices={[
        { value: "sp_index", text: "SharePoint", icon: sharepoint_icon },
        { value: "email_index", text: "Mails", icon: outlookIcon },
        { value: "glpi_index", text: "GLPI", icon: glpiIcon }
    ]} onSelectionChanged={onChange} />
}

export default SearchSources
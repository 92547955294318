import React from 'react'
import pdfIcon from '../../../assets/images/search/pdfIcon.svg';
import outlookIcon from '../../../assets/images/search/outlookIcon.svg'
import documentIcon from '../../../assets/images/search/documentIcon.svg';
import defaultUser from '../../../assets/images/search/defaultUser.svg';
import folderIcon from '../../../assets/images/search/folder.svg';
import Markdown from 'react-markdown';
import rehypeRaw from "rehype-raw";

const SearchResultItem = ({ result }) => {

    const filepath = result.document.source_url;
    const fileparts = filepath ? filepath.split('/') : undefined;
    const filename = filepath ? decodeURIComponent(fileparts[fileparts.length - 1]) : result.document.title;
    const folderpath = filepath ? fileparts.slice(0, -1).join('/') : undefined;
    const foldername = filepath ? decodeURIComponent(fileparts[fileparts.length - 2]) : undefined;
    const sender = result.document.sender;
    const date = result.document.date_sent ? new Date(result.document.date_sent) : undefined;



    const getIcon = () => {
        if (sender) {
            return outlookIcon;
        }
        const extension = filename.substring(filename.lastIndexOf('.') + 1);
        switch (extension) {
            case 'pdf':
                return pdfIcon;
            default:
                return documentIcon;
        }
    }

    return (
        <div className='searchResult'>
            <div className='resultIcon'><img src={getIcon()} alt="File result icon" /></div>
            <div className='resultDetails'>
                <div className='resultTitle'><a href={filepath} target='_blank' rel="noreferrer">{filename}</a></div>
                <div className='details'>
                    {/*  */}
                    {sender && <>
                        <img src={defaultUser} alt="File author" />
                        <div>{sender}</div>
                    </>}
                    {sender && date && <div className="separator"></div>}
                    {date && <div>{date.toLocaleDateString()}</div>}
                    {/* <div className="separator"></div> */}
                    {folderpath && <a href={folderpath} target='_blank' rel="noreferrer">
                        <img src={folderIcon} alt="Folder icon" className='folder' />
                        <div>{foldername}</div>
                    </a>}
                </div>
                <div className='resultDesc'><Markdown rehypePlugins={[rehypeRaw]} >{result.document.chunk}</Markdown></div>
            </div>
        </div>
    )
}

export default SearchResultItem
import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../store/reducers/store';
import { setUser } from '../store/actions/chat';
import PrivateLayout from '../layouts/privateLayout';
import NavBar from './components/NavBar';
import config from "../config.json"
import PromptItem from './components/prompts/PromptItem';
import MultiSelectDropdown from './components/common/MultiSelectDropdown';
import PromptSearch from './components/prompts/PromptSearch';

const Prompts = () => {
    const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    const prompts = useAppSelector(state => state.prompts.prompts);

    useEffect(() => {
        const user = instance.getActiveAccount();
        if (user) {
            dispatch(setUser(user));
        }
    }, [instance, dispatch]);

    const OpenSideBar = () => {
        setSideBarBloc(true);
    };

    const CloseSideBar = () => {
        setSideBarBloc(false);
    };

    return (
        <PrivateLayout>
            <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
                <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} />
                <div className="promptsWrapper">
                    <div className="headerWrapper">
                        <div className="header">
                            <h1>Suggested Prompts</h1>
                            <div className="subTitle">Create and discover prompts that unlock more powerful ways to use Chat</div>
                        </div>
                        <div className="button">Create Prompt</div>
                    </div>
                    <div className="searchZone">
                        <MultiSelectDropdown choices={[{ text: "Test 1", value: "test1" }, { text: "Test 2", value: "test2" }, { text: "Test 3", value: "test3" }]} label={"Department"} selection={[]} />
                        <MultiSelectDropdown choices={[{ text: "Test 1", value: "test1" }, { text: "Test 2", value: "test2" }, { text: "Test 3", value: "test3" }]} label={"Category"} selection={[]} />
                        <PromptSearch />
                    </div>
                    <div className="suggestions">
                        <div className="title">Suggested prompts</div>
                        <div className="content">
                            {prompts.map((p) => (<PromptItem prompt={p} key={`prompt-${p.id}`} />))}
                        </div>
                    </div>
                </div>
            </div>
        </PrivateLayout>
    );
}

export default Prompts
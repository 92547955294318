// SpinnerSaminute.js

import React from "react";
import "../../../assets/styles/base.css"; // Ensure the CSS is imported if SpinnerSaminute.js is in a different directory

const SpinnerSaminute = () => (
  <div className="spinnerSaminute" role="status" aria-live="polite" aria-label="Loading">
    <svg
      width="24"
      height="24"
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#3498db"
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(2 2)" strokeWidth="3">
          <circle strokeOpacity=".5" cx="18" cy="18" r="18" />
          <path d="M36 18c0-9.94-8.06-18-18-18">
            <animateTransform
              attributeName="transform"
              type="rotate"
              from="0 18 18"
              to="360 18 18"
              dur="1s"
              repeatCount="indefinite"
            />
          </path>
        </g>
      </g>
    </svg>
    {/* Visually hidden text for accessibility */}
    <span className="sr-onlySaminute">Loading...</span>
  </div>
);

export default SpinnerSaminute;

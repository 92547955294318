export const Utilities = {
    stringFromBinary: (str) => {
        return decodeURIComponent(atob(str).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    },
    docToBase64: (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve({ "base64": reader.result, "document": file.name });
            reader.onerror = (error) => reject(error);
        }),
    limitTextLength: (str, len) => {
        if (str.length > len) {
            return str.substring(0, len) + "..."
        } else {
            return str
        }
    }
}
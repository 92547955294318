import React, { useState } from 'react';
import { ClickAwayListener } from '@mui/base'
import config from "../../../config.json"


let arrow_down_icon; let arrow_up_icon;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
if (darkMode) {
    arrow_down_icon = require('../../../assets/images/common/arrow_down_dark.svg').default;
    arrow_up_icon = require('../../../assets/images/common/arrow_up_dark.svg').default;
} else {
    arrow_down_icon = require('../../../assets/images/common/arrow_down.svg').default;
    arrow_up_icon = require('../../../assets/images/common/arrow_up.svg').default;
}

const MultiSelectDropdown = ({ label, icon, choices, selection, onSelectionChanged }) => {
    const [selectedItems, setSelectedItems] = useState(selection);
    const [open, setOpen] = useState(false);

    const onOpen = () => {
        setOpen(!open);
    }

    const toggleChoice = (value) => {
        const update = [...selectedItems];
        const index = selectedItems.indexOf(value);
        if (index === -1) {
            update.push(value);
        } else {
            update.splice(index, 1);
        }
        setSelectedItems(update);
        if (onSelectionChanged) {
            onSelectionChanged(update);
        }
    }
    return (
        <div className="fridaySelectWrapper">
            <div className='fridaySelect' onClick={onOpen}>
                {icon && <img className='selectIcon' src={icon} alt="Select icon" />}
                <div className='selectLabel'>{label} ({selectedItems.length})</div>
                <img className='arrow' src={open ? arrow_up_icon : arrow_down_icon} alt="Toggle selection visibility icon" />

            </div>
            {open && <ClickAwayListener onClickAway={onOpen}><div className='selectChoices'>
                {choices.map((c) => <div className='choice' key={`select-choice-${c.value}`}>
                    <input type='checkbox' checked={selectedItems.indexOf(c.value) !== -1} onChange={() => toggleChoice(c.value)} />
                    <label>
                        {c.icon && <img src={c.icon} alt={`Choice icon ${c.text}`} />}
                        {c.text}
                    </label>
                </div>)}
            </div>
            </ClickAwayListener>}
        </div>
    )
}

export default MultiSelectDropdown
import { useCallback, useEffect } from "react";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";
import { useAppDispatch, useAppSelector } from "../store/reducers/store";
import { setHasConsented } from "../store/actions/chat";
import { GPTService } from "../services/GptService";

function PrivateLayout({ children }) {
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const hasConsented = useAppSelector(state => state.chat.hasConsented);
  const location = useLocation();
  const navigate = useNavigate();
  const accounts = instance.getAllAccounts();
  if (accounts.length > 0) {
    instance.setActiveAccount(accounts[0]);
  }

  const getConsent = useCallback(async () => {
    try {
      const consent = await GPTService.getHasConsented(accounts[0].localAccountId);
      dispatch(setHasConsented(consent));
    } catch (e) {
      console.error(e);
    }
  }, [dispatch, accounts]);

  useEffect(() => {
    if (hasConsented === undefined) {
      getConsent();
    } else if (!hasConsented && location.pathname.indexOf("disclaimer") === -1) {
      navigate("/disclaimer");
    } else if (hasConsented && location.pathname.indexOf("disclaimer") !== -1) {
      navigate("/home");
    }
  }, [hasConsented, getConsent, navigate]);

  return (
    <div>
      <UnauthenticatedTemplate>
        <Navigate to="/" />
      </UnauthenticatedTemplate>
      <AuthenticatedTemplate>
        {children}
      </AuthenticatedTemplate>
    </div>
  );
}

// Typechecking props for the PortalLayout
PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;

import React from 'react'
import { useNavigate } from 'react-router-dom';

const PageListItem = ({title, path, logo, isSelected}) => {
    const navigate = useNavigate();
    const onClick = () => {
        navigate(`/${path}`);
    }
  return (
    <div className={`item ${isSelected ? 'selected' : ''}`} onClick={onClick}>
        <div className='icon'>
          {logo && <img src={logo} alt={`Nav menu ${title} icon`} />}
        </div>
        <div className='label'>{title}</div>
    </div>
  )
}

export default PageListItem
import React from 'react'
import { useAppSelector } from '../../../store/reducers/store';
import TranslatedFile from './TranslatedFile';

const TranslatedFilesList = () => {
    const translatedDocuments = useAppSelector(state => state.translate.translatedDocuments);
    const error = useAppSelector(state => state.translate.error);

    return (
        <div className='results'>
            <div className='title'>Translated document</div>
            {error !== undefined && <div className='error-message'>{error}</div>}
            {translatedDocuments.length > 0 ?
                <>
                    {translatedDocuments.map((f, i) => (<TranslatedFile key={`translated-${i}`} file={f} />))}
                </>
                :
                <div className="nodocument">No translated documents</div>
            }
        </div>
    )
}

export default TranslatedFilesList
// utils/downloadMeetingMinutes.js

import { Document, Packer, Paragraph, TextRun, HeadingLevel, Table, TableRow, TableCell, WidthType, AlignmentType } from "docx";
import { saveAs } from "file-saver";


export async function downloadMeetingMinutes({
  meetingTitle,
  participants,
  quickRecap,
  summaries,
  nextSteps,
  filename = "MeetingMinutes",
}) {
  // Create a table of attendees
  const attendeeTable = new Table({
    rows: [
      new TableRow({
        children: [
          new TableCell({
            children: [new Paragraph({ text: "Name", bold: true })],
            width: { size: 50, type: WidthType.PERCENTAGE },
          }),
          new TableCell({
            children: [new Paragraph({ text: "Email", bold: true })],
            width: { size: 50, type: WidthType.PERCENTAGE },
          }),
        ],
      }),
      ...participants.map((participant) => (
        new TableRow({
          children: [
            new TableCell({
              children: [new Paragraph(participant.name || "N/A")],
              width: { size: 50, type: WidthType.PERCENTAGE },
            }),
            new TableCell({
              children: [new Paragraph(participant.email || "N/A")],
              width: { size: 50, type: WidthType.PERCENTAGE },
            }),
          ],
        })
      )),
    ],
    width: {
      size: 100,
      type: WidthType.PERCENTAGE,
    },
    alignment: AlignmentType.CENTER,
  });

  // Define the document structure
  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: "heading1",
          name: "Heading 1",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 32,
            bold: true,
          },
          paragraph: {
            spacing: { after: 240 },
          },
        },
        {
          id: "heading2",
          name: "Heading 2",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 28,
            bold: true,
          },
          paragraph: {
            spacing: { after: 200 },
          },
        },
        {
          id: "normal",
          name: "Normal",
          basedOn: "Normal",
          next: "Normal",
          quickFormat: true,
          run: {
            size: 24,
          },
        },
      ],
    },
    sections: [
      {
        properties: {},
        children: [
          // Meeting Title
          new Paragraph({
            text: meetingTitle || "Meeting Title",
            heading: HeadingLevel.HEADING_1,
            alignment: AlignmentType.CENTER,
          }),
          new Paragraph({
            text: `Date: ${new Date().toLocaleDateString()}`,
            style: "normal",
            alignment: AlignmentType.CENTER,
            spacing: { after: 300 },
          }),
          // Attendees Table
          new Paragraph({
            text: "Attendees:",
            heading: HeadingLevel.HEADING_2,
            spacing: { after: 200 },
          }),
          attendeeTable,
          new Paragraph({
            text: "Quick Recap:",
            heading: HeadingLevel.HEADING_2,
            spacing: { before: 400, after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: quickRecap || "No quick recap available.",
                size: 24,
              }),
            ],
          }),
          new Paragraph({
            text: "Summary:",
            heading: HeadingLevel.HEADING_2,
            spacing: { before: 400, after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: summaries || "No summaries available.",
                size: 24,
              }),
            ],
          }),
          new Paragraph({
            text: "Next Steps:",
            heading: HeadingLevel.HEADING_2,
            spacing: { before: 400, after: 200 },
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: nextSteps || "No next steps available.",
                size: 24,
              }),
            ],
          }),
          // Optional: Add a page break at the end
          new Paragraph({
            children: [new TextRun({ text: "", break: 1 })],
          }),
        ],
      },
    ],
  });

  try {
    // Generate the Word document as a Blob
    const blob = await Packer.toBlob(doc);
    // Trigger the file download
    saveAs(blob, `${filename}.docx`);
  } catch (error) {
    console.error("Error creating document:", error);
    throw new Error("Failed to generate Word document. Please try again.");
  }
}




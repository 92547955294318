import React, { useEffect, useMemo, useState } from 'react'
import { useMsal } from "@azure/msal-react";
import darkmode_icon from '../../../assets/images/sidebar/darkmode.svg'
import lightmode_icon from '../../../assets/images/sidebar/lightmode.svg'

const darkModeStorage = JSON.parse(localStorage.getItem('darkMode')) || false;
const UserProfile = () => {
    const { instance } = useMsal();
    const username = useMemo(() => { return instance.getActiveAccount().name; }, [instance]);
    const email = useMemo(() => { return instance.getActiveAccount().username; }, [instance]);
    const [darkMode, setDarkMode] = useState(darkModeStorage);
    const initial = useMemo(() => { return instance.getActiveAccount().username.charAt(0).toLocaleUpperCase(); }, [instance]);

    const toggleDarkMode = (ev) => {
        ev.preventDefault();
        setDarkMode(!darkMode);
        window.location.reload();
        localStorage.setItem('darkMode', JSON.stringify(!darkMode));
    };

    // Persisting dark mode changes to localStorage (optional)
    useEffect(() => {
        const storedMode = localStorage.getItem('darkMode');
        if (storedMode) {
            setDarkMode(JSON.parse(storedMode));
        }
    }, []);

    return (
        <div className='userProfile'>
            <div className='top'>
                <div className='icon'>
                    <span className="userCharacter"> {initial} </span>
                </div>
                <div className='infos'>
                    <div className='name' title={username}>{username}</div>
                    <div className='email' title={email}>{email}</div>
                </div>
            </div>
            <div className='separator'></div>
            <div className='bottom'>
                <div className={`button ${darkMode === false ? 'selected' : ''}`} onClick={toggleDarkMode}>
                    <img src={lightmode_icon} alt="Light mode" />
                    Light
                </div>
                <div className={`button ${darkMode ? 'selected' : ''}`} onClick={toggleDarkMode}>
                    <img src={darkmode_icon} alt="Dark mode" />
                    Dark
                </div>
            </div>
        </div>
    )
}

export default UserProfile
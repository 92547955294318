import arrowUp from '../.././assets/images/arrow_upward.svg';
import pause from '../.././assets/images/pause.svg';
import { useRef, useEffect, useState } from "react";
import * as React from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAppDispatch, useAppSelector } from '../../store/reducers/store';
import { abortChat, addFiles, saveChat, sendChat, setQuestion, setSearchTarget } from '../../store/actions/chat';
import { Constants } from '../../utils/Constants';
import { removeFile } from "../../store/actions/chat"; //clearFiles
import close from '../.././assets/images/close_small.svg';
import config from "../../config.json"
import useIsAdmin from '../../hooks/useIsAdmin';
import { useMsal } from '@azure/msal-react';
let attach;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
    attach = require('../.././assets/images/attachfile_dark.svg').default;
} else {
    attach = require('../.././assets/images/attach_file.svg').default;
}

function ChatInput() {
    const dispatch = useAppDispatch();
    const isAdmin = useIsAdmin();
    const searchTarget = useAppSelector(state => state.chat.searchTarget);
    const fetching = useAppSelector(state => state.chat.fetching);
    const question = useAppSelector(state => state.chat.question);
    const fileInputRef = useRef(null);
    const chatControlRef = useRef(null);
    const textAreaRef = useRef(null); // useRef to hold textarea element
    const { instance } = useMsal();
    const [marginTop, setMarginTop] = useState(0);

    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;

    const options = [
        { value: Constants.SearchTarget.Chat, text: config.homePage.text.chatSearch, envVar: process.env.REACT_APP_URI_CHAT },
        ...(isAdmin ? [{ value: Constants.SearchTarget.Sharepoint, text: config.homePage.text.sharePointSearch, envVar: process.env.REACT_APP_URI_SHAREPOINT }] : []),
        { value: Constants.SearchTarget.Web, text: config.homePage.text.webSearch, envVar: process.env.REACT_APP_URI_WEB },
        { value: Constants.SearchTarget.GLPI, text: config.homePage.text.glpiSearch, envVar: process.env.REACT_APP_URI_GLPI },
        { value: Constants.SearchTarget.Email, text: config.homePage.text.emailSearch, envVar: process.env.REACT_APP_URI_EMAIL },
    ];

    const filteredOptions = options.filter(option => option.envVar);
    const files = useAppSelector(state => state.chat.files);

    const handleChange = (event) => {
        dispatch(setSearchTarget(event.target.value));
    };

    useEffect(() => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = textarea.scrollHeight + 'px';
            setMarginTop(0);


            const handleInput = (e) => {
                const key = e.key;
                if (key === 'Enter' && !e.shiftKey) {
                    // Default Enter press, no need to adjust height
                    return;
                }
                // Handle dynamic height for Shift + Enter and regular input
                textarea.style.height = '51px'; // Reset height first
                textarea.style.height = textarea.scrollHeight + 'px'; // Adjust height based on content
                setMarginTop(0);
                if (textarea.scrollHeight === 75) {
                    setMarginTop(-35);
                }
                else if (textarea.scrollHeight === 99) {
                    setMarginTop(-50);
                }
                else if (textarea.scrollHeight > 122) {
                    setMarginTop(-70);
                }
            }
            textarea.addEventListener('keydown', handleInput); // Use 'keydown' instead of 'input'
            return () => textarea.removeEventListener('keydown', handleInput);
        }
    }, []);

    const onQuestionChanged = (e) => {
        dispatch(setQuestion(e.target.value));
    }

    const submitQuestion = () => {
        const textarea = textAreaRef.current;
        if (textarea) {
            textarea.style.height = '51px';
            setMarginTop(0);
        }

        if (fetching) {
            dispatch(abortChat());
        } else {
            if (question === "") {
                return;
            }
            dispatch(sendChat(question)).then(({ payload }) => {
                if (config.features.autosave) { //&& payload !== undefined
                    dispatch(saveChat({ account: instance.getActiveAccount(), chatHistory: undefined }));
                }
            }).catch(() => { });
        }
    }

    const onKeyDown = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault();
            submitQuestion()
        }
    }



    const handleFileChange = (event) => {
        const tenMega = 1024 * 1024 * 10;
        let filesToUpload = [...event.target.files].slice(0, 3);

        let filesOk = filesToUpload.filter(f => f.size <= tenMega);
        let filesKo = filesToUpload.filter(f => f.size > tenMega);
        for (let file of filesKo) {
            alert(`File ${file.name} is more than 10MB and cannot be processed.`);
        }

        dispatch(addFiles(filesOk)); // Limiting to 3 files 
        event.target.value = "";
    }

    // const emptyDocumentList = () => {
    //     dispatch(clearFiles());
    // }

    const handleDocumentClick = (event, index) => {
        event.preventDefault(); // Prevent default link behavior
        dispatch(removeFile(index))

    };

    // Function to extract file extension (assuming valid filenames)
    function getExtension(fileName) {
        const parts = fileName.split('.');
        return parts.length > 1 ? parts[parts.length - 1] : '';
    }


    // const removeText = files.length > 1 ? "Remove files" : "Remove file";
    if (chatControlRef.current) {
        let height = chatControlRef.current.offsetHeight + 225;
        const chatsElement = document.getElementById('chats');
        if (chatsElement) {
            chatsElement.style.maxHeight = 'calc(100vh - ' + height + 'px)';
        }
    }




    return (
        <div id="chatInput">
            <div className="chatControls" ref={chatControlRef} style={{ marginTop: `${files.length > 0 ? marginTop - 50 : marginTop}px` }}>
                {/* <DocumentsNav /> */}
                {files.length > 0 && <div className="chatNav" id="docNav">
                    <div className="row">
                        <div className="col-md-11">
                            <div className="documentsNav">
                                <ul id="documentsListingNav" className="documentsListing">
                                    {files.map((document, index) => {
                                        // Extract file extension
                                        let extension = getExtension(document.name);

                                        return (
                                            <li key={`file-${index}`} className={extension}>
                                                {document.name}
                                                <button className="link" onClick={(event) => handleDocumentClick(event, index)}>
                                                    <img src={close} alt="Close" />
                                                </button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                        {/* <div className="col-md-1">
                            <div className="documentsActions">
                                <button className="link" onClick={emptyDocumentList} id="">  {removeText} </button>
                            </div>
                        </div> */}
                    </div>
                </div>}

                <textarea className="searchInput" id="searchBox" value={question} ref={textAreaRef} disabled={fetching} placeholder={config.homePage.text.inputPlaceHolder} onKeyDown={onKeyDown} onChange={onQuestionChanged} />
                <img src={attach} alt="Attach file" className="attach-file" onClick={() => fileInputRef.current.click()} />
                <img src={fetching ? pause : arrowUp} alt="Send Chat" className="send-chat" onClick={submitQuestion} />
                <input
                    type="file"
                    hidden
                    multiple
                    ref={fileInputRef}
                    onChange={handleFileChange}
                />
            </div>
            <div className="type-select">
                <Box sx={{ minWidth: 180 }}>
                    <FormControl fullWidth>
                        <Select
                            labelId="chat-version"
                            id="chat-version"
                            value={searchTarget}
                            label="Version"
                            onChange={handleChange}
                            sx={{
                                '& .MuiSelect-icon': {
                                    color: darkMode ? '#FFFFFF' : '#000000', // White for dark mode, black for light mode
                                },
                            }}
                        >

                            {filteredOptions.map(option => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.text}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>
            </div>
        </div>
    );
}

export default ChatInput;

import React from 'react'
import PromptFavorite from './PromptFavorite'

const PromptItem = ({ prompt }) => {
  return (
    <div className={`prompt ${prompt.favorite ? 'favorite' : ''}`}>
      <div className="top">
        <div className="tag">
          {prompt.tag}
        </div>
        <PromptFavorite isFavorite={prompt.favorite} id={prompt.id} />
      </div>
      <div className="details">
        <div className="title">
          {prompt.title}
        </div>
        <div className="desc">
          {prompt.description}
        </div>
      </div>
    </div>
  )
}

export default PromptItem
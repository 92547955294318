import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../store/reducers/store";
import ChatContentItem from "./ChatContentItem";
import { newChat, setQuestion } from "../../store/actions/chat";

import config from "../../config.json";

function ChatContent() {
    const history = useAppSelector(state => state.chat.history);
    const lastQuestion = history[history.length - 1];
    const dispatch = useAppDispatch();
    const chatDivRef = useRef(null);
    const onQuestionClick = (question) => {
        dispatch(setQuestion(question));
    }

    useEffect(() => {
        if (history.length > 0) {
            document.getElementById("chats").scrollTo({ behavior: "smooth", top: document.getElementById("chatAnchor").offsetTop })
        }
    }, [history])

    const onClearChat = () => {
        dispatch(newChat());
    }

    return (
        <div id="chatMessage">

            <div ref={chatDivRef} id="chats">
                {history.map((h, index) => (<ChatContentItem entry={h} key={`chat-entry-${index}`} index={index} isLast={(index + 1) === history.length} />))}
                {lastQuestion.questions_generated && lastQuestion.questions_generated.length > 0 &&
                    <div className="chatSuggestions">
                        <ul>
                            {/* {lastQuestion.questions_generated.map((q, index) => (<li key={`suggession-${index}`} onClick={() => onQuestionClick(q)}>{q}</li>))} */}
                            {lastQuestion.questions_generated && lastQuestion.questions_generated.length > 0 && (
                                lastQuestion.questions_generated.map((q, index) => (
                                    <li key={`suggestion-${index}`} onClick={() => onQuestionClick(q)}>{q}</li>
                                ))
                            )}
                        </ul>
                    </div>
                }
                {!config.features.newChat &&
                    <div id="clearChat">
                        <button className="link" onClick={onClearChat}> {config.homePage.text.editQuestion.clearChat}  </button>
                    </div>
                }

                <div id="chatAnchor" />
            </div>

        </div>
    );
}
export default ChatContent;
import React, { useState, useRef, useEffect } from "react";
import logo from '.././assets/images/logo.png';
import { Link, useNavigate } from 'react-router-dom';
import PrivateLayout from "../layouts/privateLayout";
import { GPTService } from "../services/GptService";
import { useMsal } from "@azure/msal-react";
import { setHasConsented } from "../store/actions/chat";
import { useAppDispatch } from "../store/reducers/store";

import config from "../config.json"
import useDisclaimerLanguage from "../hooks/useDisclaimerLanguage";

function Disclaimer() {
    const { instance } = useMsal();
    const [isDisabled, setIsDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const texts = useDisclaimerLanguage();
    const termsCheckboxRef = useRef(null); // useRef for checkbox reference
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
    }

    const handleCheckboxChange = () => {
        setIsDisabled(!termsCheckboxRef.current.checked); // Toggle disabled state
    };

    const handleNavigateClick = () => {
        const currentUser = {
            guidelinesAccepted: true
        }
        window.localStorage.setItem('currentUser', JSON.stringify(currentUser));
        setLoading(true);
        GPTService.makeConsent(accounts[0].localAccountId, accounts[0].username).then(() => {
            dispatch(setHasConsented(true));
            navigate("/home")
        }).catch(() => {
            setLoading(false);
        })
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <PrivateLayout>
            <div className="dark_theme">
                <div className="disclaimer_container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockHeader">
                                <img src={logo} alt={`${config.homePage.text.appName} logo`} /> {config.homePage.text.appName}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockTitle">
                                <h2>{texts.subtitle}</h2>
                                <p>{texts.introduction}</p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockContent">
                                <h2>{texts.principlesTitle}</h2>
                                <ul className="disclaimer_list">
                                    {texts.principles.map((p, i) => (<li key={`principle-${i}`}>{p}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockContent">
                                <h2>{texts.practicesTitle}</h2>
                                <ul className="disclaimer_list">
                                    {texts.practices.map((p, i) => (<li key={`practice-${i}`}>{p}</li>))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockNote">{texts.finalNote}</div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="blockAction">
                                <label className="custom-checkbox">
                                    <input type="checkbox" id="termsCheckbox" disabled={loading} ref={termsCheckboxRef} onChange={handleCheckboxChange} />
                                    {config.disclaimerPage.consent.acceptTerms}
                                </label>
                                <Link id="termsButton" className={isDisabled ? 'terms_disabled' : ''} disabled={isDisabled || loading} onClick={handleNavigateClick}> {config.disclaimerPage.consent.launchApp} </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PrivateLayout>
    );
}

export default Disclaimer;

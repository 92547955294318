import config from '../config.json';

const useDisclaimerLanguage = () => {
    const userLanguages = navigator.languages || navigator.userLanguages
    if (userLanguages !== undefined) {
        for (const lang of userLanguages) {
            let fixedLang = lang;
            if (fixedLang.indexOf("-") !== -1){
                fixedLang = fixedLang.substring(0, fixedLang.indexOf("-"));
            }
            const matchingLanguage = config.disclaimerPage.text.filter(t => t.language === fixedLang);
            if (matchingLanguage.length > 0) {
                return matchingLanguage[0];
            }
        }
    }
    return config.disclaimerPage.text.filter(t => t.language === config.disclaimerPage.default)[0];
}

export default useDisclaimerLanguage;
import * as React from 'react'
import { useAppSelector } from '../../store/reducers/store';
import { Constants } from '../../utils/Constants';

function ChatReferences({ entry, openReferences }) {
    const searchTarget = entry.deployement;
    if (entry.references === undefined || entry.references.length === 0) {
        return <></>
    }

    return (
        <>
            {entry.references.map((r, index) => {
                const fileName = r.url !== undefined && r.url !== null ? r.url : r.document;
                const title = r.document ? r.document : (r.url ? r.url.substring(r.url.lastIndexOf('/') + 1) : "Untitled");
                let fileExtension = fileName ? fileName.substring(fileName.lastIndexOf('.') + 1) : "";
                if (searchTarget && searchTarget.toLocaleLowerCase() === Constants.SearchTarget.GLPI.toLocaleLowerCase()) {
                    fileExtension = "ticket";
                } else if (searchTarget && searchTarget.toLocaleLowerCase() === Constants.SearchTarget.Web.toLocaleLowerCase()) {
                    fileExtension = "web";
                }
                else if (searchTarget && searchTarget.toLocaleLowerCase() === Constants.SearchTarget.Email.toLocaleLowerCase()) {
                    fileExtension = "mail";
                }


                // if (clickable){
                return <a href={fileName} target='_blank' rel="noreferrer" className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`} key={`reference-${index}`} onClick={openReferences}>[{index + 1}] {title}</a>
                // }else{
                //     return <span className={`gpt-documents ${entry.deployement === "web" ? "web" : fileName.substring(fileName.lastIndexOf('.') + 1)}`} key={`reference-${index}`}>[{index +1}]{r.document}</span>
                // }
            })}
        </>
    )
}

export default ChatReferences;
import React from 'react';
import ReactDOM from 'react-dom/client';
import { HashRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./appConfig";
import { Provider } from 'react-redux'
import { store } from './store/reducers/store';

const root = ReactDOM.createRoot(document.getElementById('root'));
const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize()
  .then(() => {
    root.render(
      <React.StrictMode>
        <HashRouter>
          <MsalProvider instance={msalInstance}>
            <Provider store={store}>
              <App />
            </Provider>
          </MsalProvider>
        </HashRouter>
      </React.StrictMode>
    );
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();


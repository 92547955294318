import { useAppSelector } from '../../store/reducers/store';
import { Constants } from '../../utils/Constants';
import React, { useEffect } from 'react';
import config from "../../config.json"

function ChatToughtBar({ entry, onClose }) {

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);


    const searchTarget = useAppSelector(state => state.chat.searchTarget);
    const clickable = searchTarget === Constants.SearchTarget.Web || searchTarget === Constants.SearchTarget.Sharepoint || searchTarget === Constants.SearchTarget.GLPI;

    let Klogo;
    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
    // Dynamically import styles based on dark mode
    if (darkMode) {
        Klogo = require('../.././assets/images/Klogo_dark.svg').default;
    } else {
        Klogo = require('../.././assets/images/K_logo.svg').default;
    }

    return (
        <div id="toughtBarBlock1" className="toughtbar">
            <div className="sideBarContent">
                <button className='link' onClick={onClose} > <img src={Klogo} alt="Tought logo" /> </button>
                <div className="toughtHeader">
                    <h1>{config.homePage.text.thoughtProcess.title}</h1>
                    <ul>
                        <li> {config.homePage.text.thoughtProcess.model} “{entry.gpt_model}”</li>
                        <li> {config.homePage.text.thoughtProcess.deployment} “{entry.deployement}” </li>
                    </ul>
                </div>
                <div className="contentContainer">
                    <div className="content">
                        {/* {entry.search_type && <>
                            <h2> Search Type </h2>
                            <p>{entry.search_type}</p>
                        </>} */}
                        {entry.questions_generated && entry.questions_generated.length > 0 &&
                            <>
                                <h2>{config.homePage.text.thoughtProcess.generatedQuestions} </h2>
                                {entry.questions_generated.map(g => (<p>{g}</p>))}
                            </>
                        }
                        <h2>{config.homePage.text.thoughtProcess.temperature}</h2>
                        <p>
                            {entry.temperature}
                        </p>
                        <h2>{config.homePage.text.thoughtProcess.yourQuestion}  </h2>
                        <p> {entry.question}</p>
                        <h2>{config.homePage.text.thoughtProcess.answer}  </h2>
                        <p>
                            {entry.answer}
                        </p>
                        {entry.references && entry.references.length > 0 &&
                            <>
                                <h2>{config.homePage.text.thoughtProcess.references} </h2>
                                <p>{entry.references.map((r, index) => {
                                    const fileName = r.url !== undefined && r.url !== null ? r.url : r.document;
                                    var fileExtension = fileName ? fileName.substring(fileName.lastIndexOf('.') + 1) : "";
                                    if (fileExtension.length > 5 && (fileName.includes('http') || searchTarget === "Web")) {
                                        fileExtension = "web";
                                    }
                                    else if (fileExtension.length > 5 && searchTarget === Constants.SearchTarget.Email) {
                                        fileExtension = "mail";
                                    }
                                    else if (fileExtension.length > 5 && searchTarget === Constants.SearchTarget.GLPI) {
                                        fileExtension = "ticket";
                                    }

                                    if (clickable) {
                                        return <a href={fileName} target='_blank' rel="noreferrer" className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`} key={`reference-${index}`}>{r.document}</a>
                                    } else {
                                        return <span className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`} key={`reference-${index}`}>{r.document}</span>
                                    }
                                    // return <a href={fileName} target='_blank' className={`gpt-documents ${entry.deployement === "web" ? "web" : fileName.substring(fileName.lastIndexOf('.') + 1)}`} key={`reference-${index}`}>{r.document}</a>
                                })}</p>
                            </>}
                        {entry.suggested_questions && entry.suggested_questions.length > 0 &&
                            <>
                                <h2>{config.homePage.text.thoughtProcess.suggestedQuestions} </h2>
                                {entry.suggested_questions.map(g => (<p>{g}</p>))}
                            </>
                        }
                        {/* 
                        <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum et facilisis lacus. Donec egestas, eros et sodales pharetra, quam nisi fringilla ex, eu porttitor est nibh faucibus turpis. Pellentesque eu justo efficitur, viverra turpis sed, hendrerit dui. Maecenas ac velit fermentum diam rutrum ullamcorper at a lorem. Pellentesque non fermentum tellus, non maximus lacus. In molestie, dolor ut finibus lacinia, ante ligula lacinia nunc, eu elementum lectus nulla ut orci. Sed placerat aliquet augue. Sed maximus sapien non massa placerat egestas. <br /> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis sit amet ante diam. Vestibulum ut condimentum sapien. Proin ante libero, volutpat ac ornare vel, fringilla a nibh. Fusce urna nisl, scelerisque eu accumsan vel, molestie nec quam. Mauris a leo commodo, euismod mi lobortis, imperdiet eros. Curabitur placerat ex in tellus tempor, sed efficitur sapien commodo. Fusce et tortor at massa tristique molestie eget at velit. Vestibulum </p> */}
                        <button className="link" onClick={onClose} > {config.homePage.text.thoughtProcess.windowClose} </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ChatToughtBar;
// generateQuickRecap.js

import axios from 'axios';

export const generateQuickRecap = async (meetingNotes) => {
  try {
    // Load environment variables
    const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const endpointBase = process.env.REACT_APP_OPENAI_ENDPOINT;
    const deploymentName = 'gpt-4o'; // Replace with your actual deployment name

    if (!apiKey || !endpointBase) {
      throw new Error('API key or endpoint is not defined. Check your environment variables.');
    }

    const endpoint = `${endpointBase}/openai/deployments/${deploymentName}/chat/completions?api-version=2024-02-15-preview`;

    // Define the token limit for chunking
    const MAX_TOKENS_PER_CHUNK = 4000; // Adjust based on model capacity

    // Split the input into manageable chunks
    const inputChunks = splitText(meetingNotes, MAX_TOKENS_PER_CHUNK);

    let partialRecaps = [];

    for (let idx = 0; idx < inputChunks.length; idx++) {
      const chunk = inputChunks[idx];
      // Define the prompt for generating a quick recap
      const recapPrompt = [
        {
          role: 'system',
          content: `You are an AI assistant tasked with creating a concise, professional quick recap for a meeting in the same language of the meeting.
Based on the provided meeting notes and next steps:
- Write a **single paragraph**, no more than 100 words.
- Focus on the key discussions, decisions, and planned actions.
- Avoid unnecessary details or subjective commentary. Ensure the output is clear and professional.`,
        },
        {
          role: 'user',
          content: chunk,
        },
      ];

      try {
        console.log(`Processing chunk ${idx + 1}/${inputChunks.length}...`);

        const response = await axios.post(
          endpoint,
          {
            messages: recapPrompt,
            max_tokens: 200, // Ensure concise output
            temperature: 0.3, // Lower temperature for consistent results
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'api-key': apiKey,
            },
          }
        );

        const partialRecap = response.data.choices[0].message.content.trim();
        partialRecaps.push(partialRecap);
      } catch (error) {
        console.error(`Error processing chunk ${idx + 1}:`, error);
        continue;
      }
    }

    // Combine partial recaps
    const finalRecap = partialRecaps.join(' ').trim();

    return finalRecap;
  } catch (error) {
    console.error('Error generating quick recap:', error);
    throw error;
  }
};

// Helper function to split text into chunks based on token limit
const splitText = (text, maxTokens) => {
  const chunks = [];
  let currentChunk = '';

  const sentences = text.match(/[^\.!\?]+[\.!\?]+/g) || [text]; // Split by sentences
  for (const sentence of sentences) {
    const sentenceLength = sentence.split(' ').length;
    const currentChunkLength = currentChunk.split(' ').length;

    if (currentChunkLength + sentenceLength > maxTokens) {
      chunks.push(currentChunk);
      currentChunk = sentence;
    } else {
      currentChunk += ' ' + sentence;
    }
  }

  if (currentChunk) {
    chunks.push(currentChunk);
  }

  return chunks;
};

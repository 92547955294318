import React from "react";
import config from "../../config.json"
import PageList from "./sideBar/PageList";
import UserProfile from "./sideBar/UserProfile";

let sideClose;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
  sideClose = require('../.././assets/images/sideclose_dark.svg').default;
} else {
  sideClose = require('../.././assets/images/side_close.svg').default;
}
const SideBar = ({ displaySide, onDisplaySideChange, menuContent }) => {

  const handleCloseClick = (ev) => {
    ev.preventDefault();
    onDisplaySideChange();
  };

  return (
    <div>
      {displaySide && (
        <div id="sideBarBlock" className="sidebar">
          <div className="sideBarContent">
            <button className='link' id="side_close" onClick={handleCloseClick}>
              <img src={sideClose} alt="Close" />
            </button>
            <PageList />
            {menuContent && menuContent}
            <UserProfile />
          </div>

        </div>
      )}
    </div>

  );
}

export default SideBar;

import React from 'react'
import search_icon from '../../../assets/images/search/search.svg'

const PromptSearch = () => {
    return (
        <div className="searchBox">
            <img className="searchIcon" src={search_icon} alt="Search icon" />
            <input type="text" placeholder="Search by app name or category" />
            <button>Search</button>
        </div>
    )
}

export default PromptSearch
import React, { useCallback, useEffect, useState } from 'react'
import { useAppDispatch } from '../../../store/reducers/store'
import { deleteConversation, renameConversation, selectConversation } from '../../../store/actions/chat';
import config from "../../../config.json"
import { useMsal } from '@azure/msal-react';
let editIcon; let deleteIcon; let okIcon; let cancelIcon;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
    editIcon = require('../../../assets/images/edit_dark.svg').default;
    deleteIcon = require('../../../assets/images/delete_dark.svg').default;
    okIcon = require('../../../assets/images/sidebar/ok_icon.svg').default;
    cancelIcon = require('../../../assets/images/sidebar/cancel_icon.svg').default;
} else {
    editIcon = require('../../../assets/images/edit.svg').default;
    deleteIcon = require('../../../assets/images/delete.svg').default;
    okIcon = require('../../../assets/images/sidebar/ok_icon.svg').default;
    cancelIcon = require('../../../assets/images/sidebar/cancel_icon.svg').default;
}

const ChatListItem = ({ item, selected, index, onSave }) => {
    const [isEditing, setIsEditing] = useState(false);
    const [editName, setEditName] = useState(item.ConversationTitle);
    const { instance } = useMsal();
    const dispatch = useAppDispatch();
    let itemName = item.ConversationTitle;

    const onClick = () => {
        dispatch(selectConversation({ conversationID: item.ConversationID, index: index, account: instance.getActiveAccount() }));
    }

    const onDelete = () => {
        dispatch(deleteConversation({ conversationID: item.ConversationID, index: index }))
    }

    const onEdit = () => {
        setIsEditing(true);
    }

    const onNameChanged = (e) => {
        setEditName(e.currentTarget.value);

    }
    const onSaveRename = () => {
        dispatch(renameConversation({ conversationID: item.ConversationID, index: index, title: editName }));
        itemName = editName;
        setIsEditing(false);
    }
    const onCancel = useCallback(() => {
        setEditName(itemName);
        setIsEditing(false);
    }, [itemName]);

    useEffect(() => {
        if (isEditing && !selected) {
            onCancel();
        }
    }, [selected, isEditing, onCancel])
    if (isEditing) {
        return <li className={`chat ${selected ? 'chat-selected' : ''}`}>
            <input type='text' value={editName} onChange={onNameChanged} />
            <button className='link' onClick={onCancel}>
                <img src={cancelIcon} alt="Cancel" />
            </button>
            <button className='link' onClick={onSaveRename}>
                <img src={okIcon} alt="Ok" />
            </button>
        </li>
    }
    return <li className={`chat ${selected ? 'chat-selected' : ''}`} onClick={onClick}>
        <span> {editName} </span>
        {selected && <>
            {!config.features.autosave && <button className='link' onClick={onSave}>Save</button>}
            <button className='link' onClick={onDelete}>
                <img src={deleteIcon} alt="Delete" />
            </button>
            <button className='link' onClick={onEdit}>
                <img src={editIcon} alt="Rename" />
            </button>
        </>
        }
    </li>
}

export default ChatListItem;
import { createReducer } from "@reduxjs/toolkit";
import { toggleFavorite } from "../actions/prompts";

const defaultPromps = [
    { id: 1, favorite: true, tag: "All teams", title: "Summarize Meeting feedback", description: "Summarize meeting feedback and identify top reasons and risks for hiring someone." },
    { id: 2, favorite: false, tag: "ENGINEERING", title: "Debug an error message", description: "Identify the cause of an error message and how to resolve the issue." },
    { id: 3, favorite: false, tag: "All teams", title: "Ask about a company policy", description: "Find and research company policies and answer specific questions." },
    { id: 4, favorite: false, tag: "All teams", title: "Find teammates who worked on a Project", description: "Identify individuals who have worked on a feature in the past." },
    { id: 5, favorite: false, tag: "All teams", title: "Translate document into another language", description: "Translate document into any language quickly and accurately." },
    { id: 6, favorite: false, tag: "ENGINEERING", title: "Analyze and debug production issue", description: "Analyze and debug production issue prompt." },
    { id: 7, favorite: false, tag: "All teams", title: "Find recent mentions of a topic in Slack", description: "Search the specified Slack channel for messages that mentioned the given topic in the past month. Summarize the relevant discussions." },
    { id: 8, favorite: false, tag: "Design", title: "Synthesize user testing observations", description: "Analyze user feedback to identify and categorize key issues by severity and customer impact." },
    { id: 9, favorite: false, tag: "ENGINEERING", title: "Brainstorm names for a UX component", description: "Suggest 5 names for a UX component based on its function and explain the best choice clearly." },
]

const initialState = {
    prompts: [...defaultPromps]
}

const promptReducer = createReducer(initialState, (builder) => {
    builder.addCase(toggleFavorite, (state, action) => {
        let newPromps = [...state.prompts];
        let index = newPromps.findIndex(p => p.id === action.payload);
        newPromps[index].favorite = !newPromps[index].favorite;
        state.prompts = newPromps;
    })
});

export default promptReducer;
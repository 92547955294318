import config from '../config.json';

export const Constants = {
    GPTVersion: {
        "3.5": "gpt3.5turbo",
        "4": "gpt4.0"
    },
    SearchTarget: {
        Chat: "Chat",
        Sharepoint: "Sharepoint",
        Web: "Web",
        Document: "Document",
        GLPI: "GLPI",
        Email: "Email"
    },
    SearchType: [
        "vector similarity search",
        "hybrid search",
        "hybrid semantic search"
    ],
    URI: {
        Chat: config.features.streaming ? process.env.REACT_APP_URI_CHAT_STREAMING : process.env.REACT_APP_URI_CHAT,
        Sharepoint: config.features.streaming ? process.env.REACT_APP_URI_SHAREPOINT_STREAMING : process.env.REACT_APP_URI_SHAREPOINT,
        Web: config.features.streaming ? process.env.REACT_APP_URI_WEB_STREAMING : process.env.REACT_APP_URI_WEB,
        Document: config.features.streaming ? process.env.REACT_APP_URI_DOCINSIGHT_STREAMING : process.env.REACT_APP_URI_DOCINSIGHT,
        GLPI: config.features.streaming ? process.env.REACT_APP_URI_GLPI_STREAMING : process.env.REACT_APP_URI_GLPI,
        Email: config.features.streaming ? process.env.REACT_APP_URI_EMAIL_STREAMING : process.env.REACT_APP_URI_EMAIL,
        Regenerate: config.features.streaming ? process.env.REACT_APP_URI_REGENERATE_STREAMING : process.env.REACT_APP_URI_REGENERATE,
        Translation: process.env.REACT_APP_URI_CHAT,
        DocumentTranslation: process.env.REACT_APP_URI_TRANSLATE_DOCUMENT,
        GetConsent: process.env.REACT_APP_URI_GETCONSENT,
        CreateConsent: process.env.REACT_APP_URI_CREATECONSENT,
        GetUserChats: process.env.REACT_APP_GET_USER_CHAT,
        GetChatHistory: process.env.REACT_APP_GET_CHAT_HISTORY,
        AddChatHistory: process.env.REACT_APP_ADD_CHAT_HISTORY,
        UpdateChatHistory: process.env.REACT_APP_UPDATE_CHAT_HISTORY,
        RenameChatHistory: process.env.REACT_APP_RENAME_CHAT_HISTORY,
        DeleteChatHistory: process.env.REACT_APP_DELETE_CHAT_HISTORY,
        AzureSearch: process.env.REACT_APP_AZURE_SEARCH,
        GetUserMeetings: process.env.REACT_APP_GET_USER_MEETINGS,
        GetTranscript: process.env.REACT_APP_GET_TRANSCRIPT,
        GetMeetingAttendance: process.env.REACT_APP_GET_MEETING_ATTENDANCE,
        GetMeetingAttendanceDetails: process.env.REACT_APP_GET_MEETING_ATTENDANCE_DETAILS,
    },
    Redirections: {
        SharePointLibrary: process.env.REACT_APP_URLSHAREPOINTDOCLIB
    },
    FunctionKey: "f0dc994526894559ae7ad2f047f380b6"
};


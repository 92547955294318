import React from "react";
import decoration from '../.././assets/images/decoration.svg';
import { useAppDispatch } from '../../store/reducers/store';
import { setQuestion } from "../../store/actions/chat";
import config from "../../config.json"

function HomeContent() {
    const dispatch = useAppDispatch()

    const suggestions = [config.homePage.text.suggestionBox.left, config.homePage.text.suggestionBox.middle, config.homePage.text.suggestionBox.right]


    const onQuestionClick = (question) => {
        dispatch(setQuestion(question));
    }

    return (
        <div id="chatTemplate">
            <img src={decoration} alt="Chat icon" />
            <h1>{config.homePage.text.title}</h1>
            {config.homePage.showSuggestionBox && (
                <ul className="chatSuggestions">
                    {suggestions.map((s, i) => (
                        <li key={`suggestion-${i}`}>
                            <button className="link" onClick={() => onQuestionClick(s)}>{s}</button>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

}
export default HomeContent;
import React, { useEffect } from "react";
import { useMsal } from '@azure/msal-react';
import { Routes, Route } from 'react-router-dom';
import { useAppDispatch } from './store/reducers/store'
import { setFavicon } from "./utils/setFavicons.js";
import config from "./config.json"
import { loginRequest } from "./appConfig.js";
import { setToken } from "./store/actions/chat.js";
import { RoutesConstant } from "./utils/Routes.js";

const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
  import('./assets/styles/dark.css').then(() => {
    console.log('Dark mode styles loaded');
  }).catch(err => {
    console.error('Failed to load dark mode styles', err);
  });
} else {
  import('./assets/styles/style.css').then(() => {
    console.log('Light mode styles loaded');
  }).catch(err => {
    console.error('Failed to load light mode styles', err);
  });
}

function App() {
  //const { instance } = useMsal();
  // const [accessToken, setAccessToken] = useState("");
  const { instance, accounts } = useMsal();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (instance && accounts.length > 0) {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance.acquireTokenSilent(request)
        .then((response) => {
          dispatch(setToken({ token: response.accessToken, expiresOn: response.expiresOn }));
          // console.log("Access Token:", response.accessToken);
        })
        .catch((error) => {
          console.error("Token acquisition failed", error);
        });
    }
  }, [dispatch, accounts, instance]);

  useEffect(() => {
    // Ensure loginRedirect is called only if no accounts are found
    //const accounts = instance.getAllAccounts();
  }, []);

  useEffect(() => {
    document.title = config.browser.title || 'Default Title';
    setFavicon(config.browser.favicon || '/favicon.ico');
  }, []);

  return (
    <>
      <Routes>
        {RoutesConstant.map(r => (<Route path={`/${r.path}`} element={r.element} key={`route-${r.path}`} />))}
        {/* <Route path="/" element={<Login />} />
          <Route path="/disclaimer" element={<Disclaimer />} />
          <Route path="/home" element={<Home />} />
          <Route path="/chat" element={<Chat />} />
          <Route path="/translate" element={<Translate />} />
          <Route path="/documents" element={<Documents />} /> 
          <Route path="/redirectLogin" element={<RedirectLoginPage />} /> */}
      </Routes>
    </>
  );
}


export default App;


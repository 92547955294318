import { createReducer } from "@reduxjs/toolkit";
import { search, setFormat, setIndex, setText } from "../actions/search";

const initialState = {
    index: ["sp_index"],
    formats: ["pdf", "doc*", "xls*"],
    text: "",
    results: [],
    searching: false
}

const searchReducer = createReducer(initialState, (builder) => {
    builder.addCase(setIndex, (state, action) => {
        state.index = action.payload;
    });
    builder.addCase(setFormat, (state, action) => {
        state.formats = action.payload;
    });

    builder.addCase(setText, (state, action) => {
        state.text = action.payload;
    })

    builder.addCase(search.pending, (state) => {
        state.searching = true;
        state.results = []
    });
    builder.addCase(search.fulfilled, (state, action) => {
        state.searching = false;
        state.results = action.payload;
    })
});


export default searchReducer;
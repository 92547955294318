
import React, { useEffect, useState } from "react";
import NavBar from './components/NavBar.js';
import PrivateLayout from "../layouts/privateLayout.js";
import { useAppDispatch } from "../store/reducers/store.js";
import { setUser } from "../store/actions/chat.js";
import config from "../config.json"
import { useMsal } from "@azure/msal-react";
import SearchResults from "./components/search/SearchResults.js";
import SearchBar from "./components/search/SearchBar.js";
import SearchSources from "./components/search/SearchSources.js";
import SearchFormats from "./components/search/SearchFormats.js";

function Search() {
    const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
    const { instance } = useMsal();
    const dispatch = useAppDispatch();

    useEffect(() => {
        const user = instance.getActiveAccount();
        if (user) {
            dispatch(setUser(user));
        }
    }, [instance, dispatch]);

    const OpenSideBar = () => {
        setSideBarBloc(true);
    };

    const CloseSideBar = () => {
        setSideBarBloc(false);
    };

    return (
        <PrivateLayout>
            <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
                <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} />
                <div className="searchWrapper">
                    <SearchBar />
                    <div className="searchConfig">
                        <SearchSources />
                        <SearchFormats />
                    </div>
                    <SearchResults />
                </div>
            </div>
        </PrivateLayout>
    );
}

export default Search;

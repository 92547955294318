import React, { useState } from 'react'
import PrivateLayout from '../layouts/privateLayout'
import NavBar from './components/NavBar';
import config from "../config.json"
import FileSelector from './components/translation/FileSelector';
import TranslatedFilesList from './components/translation/TranslatedFilesList';
import TextInput from './components/translation/TextInput';
import TranslatedText from './components/translation/TranslatedText';

const Translate = () => {
    const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
    const [pageIndex, setPageIndex] = useState(0);
    // const [translatedFiles, setTranslatedFiles] = useState([]);

    const OpenSideBar = () => {
        setSideBarBloc(true);
    };

    const CloseSideBar = () => {
        setSideBarBloc(false);
    };

    return (
        <PrivateLayout>
            <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
                <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} />
                <div className='translationWrapper'>
                    <div className="translationSelection">
                        <div className={`translationType left ${pageIndex === 0 ? 'selected' : ''}`} onClick={() => setPageIndex(0)}>Text</div>
                        <div className="separator"></div>
                        <div className={`translationType right ${pageIndex === 1 ? 'selected' : ''}`} onClick={() => setPageIndex(1)}>Documents</div>
                    </div>
                    {pageIndex === 0 ?
                        <div className="translationMain">
                            <TextInput />
                            <TranslatedText />
                        </div>
                        :
                        <div className="translationDocuments">
                            <FileSelector />
                            <TranslatedFilesList />
                        </div>
                    }
                </div>
            </div>
        </PrivateLayout>
    );
}

export default Translate
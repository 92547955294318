import React, { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from '../../store/reducers/store';
import { regenerate, saveChat } from "../../store/actions/chat";
import { useMsal } from "@azure/msal-react";
import config from "../../config.json"
import ChatReferenceItem from "./chatReferences/ChatReferenceItem";

function ChatReferencesBar({ entry, questionIndex, isLast, onClose }) {
    const dispatch = useAppDispatch();
    const { instance } = useMsal();

    useEffect(() => {
        const handleEscape = (event) => {
            if (event.keyCode === 27) {
                onClose();
            }
        };
        window.addEventListener('keydown', handleEscape);
        return () => {
            window.removeEventListener('keydown', handleEscape);
        };
    }, [onClose]);

    const searchTarget = useAppSelector(state => state.chat.searchTarget);
    // const clickable = searchTarget === Constants.SearchTarget.Web || searchTarget === Constants.SearchTarget.Sharepoint || searchTarget === Constants.SearchTarget.GLPI;

    let Klogo; let filterRegularIcon; let filterClickedIcon;
    const darkModeStorage = localStorage.getItem('darkMode');
    const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
    const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
    if (darkMode) {
        Klogo = require('../.././assets/images/Klogo_dark.svg').default;
        filterRegularIcon = require('../.././assets/images/filter_dark.svg').default;
        filterClickedIcon = require('../.././assets/images/filterclicked_dark.svg').default;
    } else {
        Klogo = require('../.././assets/images/K_logo.svg').default;
        filterRegularIcon = require('../.././assets/images/filter.svg').default;
        filterClickedIcon = require('../.././assets/images/filterclicked.svg').default;
    }
    // State to manage the current filter icon
    const [filterIcon, setFilterIcon] = useState(filterRegularIcon);
    const [isFilterVisible, setIsFilterVisible] = useState(false);
    const [isChunksVisible, setIsChunksVisible] = useState(false);
    const [isManageReferences, setIsManageReferences] = useState(false);
    const handleFilterVisibleClick = () => {
        setIsFilterVisible(!isFilterVisible);
        // Toggle between default and active icons
        setFilterIcon((prevIcon) =>
            prevIcon === filterRegularIcon ? filterClickedIcon : filterRegularIcon
        );
    };
    const handleChunksVisibleClick = () => {
        setIsChunksVisible(!isChunksVisible);
        handleFilterVisibleClick();
    };
    const manageReferencesClick = () => {
        setIsManageReferences(!isManageReferences);
        handleFilterVisibleClick();
    };

    let unusedChunks = [], usedChunks = [];
    if (entry.chunks) {
        unusedChunks = entry.chunks.filter((chunk) => chunk && chunk.chunk_state !== "used").filter((chunk) => Object.keys(chunk).length > 0);
        usedChunks = entry.chunks.filter((chunk) => chunk && chunk.chunk_state === "used").filter((chunk) => Object.keys(chunk).length > 0);
    }

    const [checkedStates, setCheckedStates] = useState(() => {
        // Create an object with default true values for all references
        return usedChunks.reduce((acc, _, index) => {
            return { ...acc, [`reference-${index}`]: true };
        }, unusedChunks.reduce((acc, _, index) => {
            return { ...acc, [`chunck-${index}`]: false };
        }, {})
        );
    });

    function getIndexFromReference(reference) {
        reference = reference.toString();
        // Extract the numeric part using regular expression
        const match = reference.match(/-\d+/);
        if (match) {
            // Remove the hyphen and convert to integer
            return parseInt(match[0].substring(1));
        }
        return -1; // Return -1 if no index is found
    }


    const onRegenerate = () => {
        var updatedEntry = [];
        const checkedChunks = [];
        for (const [name, isChecked] of Object.entries(checkedStates)) {
            const index = getIndexFromReference(name);
            if (isChecked && name.toString().includes("reference")) {
                // const referenceToPush = entry.references[index];
                // if (entry.chunks) {
                //     const matchingChunk = entry.chunks.find((chunk) => chunk.chunk_content ? chunk.chunk_content.metadata.file_name === referenceToPush.document : chunk.title === referenceToPush.document);
                //     const matchingChunk = entry.chunks.find((chunk) => chunk.chunk_content ? chunk.chunk_content.metadata.file_name === referenceToPush.document : chunk.title === referenceToPush.document);
                //     if (matchingChunk) {
                //         checkedChunks.push(matchingChunk);
                //     }
                // }
                checkedChunks.push(usedChunks[index]);
            }
            else if (isChecked && name.toString().includes("chunck")) {
                checkedChunks.push(unusedChunks[index]);
            }
        }

        updatedEntry.question = entry.question;
        updatedEntry.gpt_model = entry.gpt_model;
        updatedEntry.temperature = entry.temperature;
        updatedEntry.chunks = checkedChunks;
        dispatch(regenerate({ question: updatedEntry, index: questionIndex })).then(({ payload }) => {
            if (config.features.autosave && payload !== undefined) {
                dispatch(saveChat({ account: instance.getActiveAccount(), chatHistory: undefined }));
            }
        }).catch(() => { });
        onClose();
    }



    return (
        <div id="referenceBarBlock1" className="toughtbar">
            <div className="sideBarContent">
                <div className="sideBarHeader">
                    <button className="link" id="close_tought" onClick={onClose}> <img src={Klogo} alt="Close" /> </button>
                    {isLast && <button id="close_tought" className="link regenerate" onClick={onRegenerate}> {config.homePage.text.references.regenerateAnswer} </button>}
                </div>
                <div className="toughtHeader">
                    <h1> {config.homePage.text.references.title}</h1>
                    <span className="referencesFilter">
                        <button className="link" id="filterButton" onClick={handleFilterVisibleClick}>
                            <img src={filterIcon} alt="Filter" />
                        </button>
                        {isFilterVisible && (
                            <div className="filterToggle">
                                <button className="link" id="filterReferncesButton" onClick={handleChunksVisibleClick}>
                                    {isChunksVisible ? config.homePage.text.references.hideNotUsedReferences : config.homePage.text.references.showNotUsedReferences}
                                </button>
                                <button id="manageReferencesButton" className="link borderedFilter" onClick={manageReferencesClick}> {config.homePage.text.references.manageReferences}</button>
                            </div>
                        )}
                    </span>
                </div>

                <div className="contentContainer">
                    <div className="content">

                        {entry.references.map((r, index) => (<ChatReferenceItem reference={r} index={index} checkedStates={checkedStates} entry={entry} isManageReferences={isManageReferences} searchTarget={searchTarget} setCheckedStates={setCheckedStates} key={`reference-${index}`} />))}
                        {isChunksVisible && unusedChunks && unusedChunks.length > 0 && <>
                            <div className="separator"></div>
                            {unusedChunks.map((r, index) => (<ChatReferenceItem reference={r} index={index} checkedStates={checkedStates} entry={entry} isManageReferences={isManageReferences} searchTarget={searchTarget} setCheckedStates={setCheckedStates} key={`chunck-${index}`} unusedChunck={true} />))}
                        </>}
                        {/* {isChunksVisible && unusedChunks && unusedChunks.length > 0 && unusedChunks.map((r, index) => {
                            let fileName = r.chunk_content.metadata !== undefined && r.chunk_content.metadata.source_url !== null ? r.chunk_content.metadata.source_url : r.chunk_content.metadata.title;
                            if (searchTarget === Constants.SearchTarget.Email && fileName === undefined) {
                                fileName = r.chunk_content.metadata.file_name
                            }
                            let fileExtension = "";
                            if (fileName && fileName.trim()) {
                                fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1);
                            }
                            if (fileExtension.length > 5 && searchTarget === "Web") {
                                fileExtension = "web";
                            }
                            else if (fileExtension.length > 5 && searchTarget === Constants.SearchTarget.Email) {
                                fileExtension = "mail";
                            }
                            else if (fileExtension.length > 5 && searchTarget === Constants.SearchTarget.GLPI) {
                                fileExtension = "ticket";
                            }
                            return <>
                                {entry.deployement === "sharepoint" && <h2>{config.homePage.text.document}</h2>}
                                <span>
                                    {isManageReferences && (
                                        <label htmlFor={`checkboxChunk-${index}`}>
                                            <input
                                                type="checkbox"
                                                id={`checkboxChunk-${index}`}
                                                name={`chunk-${index}`}
                                                value={fileName}
                                                checked={checkedStates[`chunk-${index}`]}
                                                onChange={(e) => {
                                                    setCheckedStates({ ...checkedStates, [e.target.name]: e.target.checked });
                                                }}
                                            />
                                            <img className="referenceCheck" src={checkedStates[`chunk-${index}`] ? radioChecked : radioUnchecked} alt={checkedStates[`chunk-${index}`] ? "yes" : "no"} />
                                        </label>
                                    )}

                                    {clickable ?
                                        <a
                                            href={fileName.includes('sharepoint') ? `${fileName}?web=1` : fileName}
                                            target='_blank'
                                            rel="noreferrer"
                                            className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`}
                                        >
                                            [{index + 1}] {r.chunk_content.source}
                                        </a>
                                        :
                                        <span className={`gpt-documents ${entry.deployement === "web" ? "web" : fileExtension}`}>
                                            [{index + 1}] {r.chunk_content.source}
                                        </span>
                                    }
                                </span>
                                <span className="notUsedReference">
                                    {config.homePage.text.references.notUsedReferences}
                                </span>
                                <div>
                                    <p>
                                        {r.chunk_content.content}
                                    </p>
                                </div>
                            </>
                        })} */}
                        <div>
                            <button className="link button" id="close_tought" onClick={onClose}> {config.homePage.text.references.windowClose} </button>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    )
}

export default ChatReferencesBar;
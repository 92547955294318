
import React, { useEffect, useState } from "react";
import NavBar from './components/NavBar.js';
import ChatInput from './components/ChatInput.js';
import PrivateLayout from "../layouts/privateLayout.js";
import HomeContent from "./components/HomeContent.js";
import { useAppDispatch, useAppSelector } from "../store/reducers/store.js";
import ChatContent from "./components/ChatContent.js";
import { setGPTVersion, setUser } from "../store/actions/chat.js";
import config from "../config.json"
import { useMsal } from "@azure/msal-react";
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Constants } from "../utils/Constants.js";
import Settings from "./components/Settings.js";
import ChatList from "./components/sideBar/ChatList.js";


const darkModeStorage = JSON.parse(localStorage.getItem('darkMode')) || false;
let settings; let symbol;

if (darkModeStorage) {
  settings = require('.././assets/images/settings_dark.svg').default;
  symbol = require('.././assets/images/sharepoint_dark.svg').default;
} else {
  settings = require('.././assets/images/settings.svg').default;
  symbol = require('.././assets/images/sharepoint.svg').default;
}

function Home() {
  const [showSideBarBlock, setSideBarBloc] = useState(config.features.newChat);
  const history_length = useAppSelector(state => state.chat.history.length);
  const { instance } = useMsal();
  const dispatch = useAppDispatch();
  const version = useAppSelector(state => state.chat.gptVersion);
  const [showSettingsBlock, setShowSettingsBlock] = useState(false);


  const OpenSettings = (ev) => {
    ev.preventDefault();
    setShowSettingsBlock(true);
  };

  const CloseSettings = (ev) => {
    setShowSettingsBlock(false);
  };

  useEffect(() => {
    const user = instance.getActiveAccount();
    if (user) {
      dispatch(setUser(user));
    }
  }, [instance, dispatch]);

  const OpenSideBar = () => {
    setSideBarBloc(true);
  };

  const CloseSideBar = () => {
    setSideBarBloc(false);
  };

  const handleChange = (event) => {
    dispatch(setGPTVersion(event.target.value));
  };

  return (
    <PrivateLayout>
      <div id="main" style={{ marginLeft: showSideBarBlock ? '255px' : '0px' }} >
        <Settings display={showSettingsBlock} onDisplayChange={CloseSettings} />
        <NavBar displaySide={showSideBarBlock} onDisplaySideOpen={OpenSideBar} onDisplaySideClose={CloseSideBar} customActions={
          <>
            <FormControl sx={{
              root: { width: "120px" }
            }}>
              <Select
                labelId="chat-version"
                id="chat-version"
                value={version}
                label="Version"
                onChange={handleChange}
                sx={{
                  '& .MuiSelect-select': {
                    padding: 0
                  },
                  '& .MuiSelect-icon': {
                    // color: darkMode ? '#FFFFFF' : '#000000', // White for dark mode, black for light mode
                  },
                }}
              >
                <MenuItem value={Constants.GPTVersion["4"]}>GPT4</MenuItem>
                {/* <MenuItem value={Constants.GPTVersion["3.5"]} >GPT 3.5</MenuItem> */}
              </Select>
            </FormControl>
            <a className="nav-link" rel="noreferrer" href={Constants.Redirections.SharePointLibrary} target="_blank">
              <img src={symbol} alt="Symbol" />
            </a>
            <button className="link nav-link" onClick={OpenSettings}>
              <img src={settings} alt="Settings" />
            </button>
          </>
        } menuContent={<>{config.features.newChat && <>
          <hr />
          <ChatList />
        </>
        }</>} />
        <div className="chatBody">
          {history_length === 0 ?
            <HomeContent />
            :
            <ChatContent />
          }

          <ChatInput />
        </div>
      </div>
    </PrivateLayout>
  );
}

export default Home;

import React, { useState } from 'react'
import config from "../../../config.json"
import { sendTranslation, setText } from '../../../store/actions/translate';
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store';


let copy; let check;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
    copy = require('../../../assets/images/copy_dark.svg').default;
    check = require('../../../assets/images/check_small_dark.svg').default;
} else {
    copy = require('../../../assets/images/copy.svg').default;
    check = require('../../../assets/images/check_small.svg').default;
}

const TextInput = () => {
    const dispatch = useAppDispatch();
    const sourceText = useAppSelector(state => state.translate.sourceText);
    const loading = useAppSelector(state => state.translate.loading);
    // const sendTimeOutId = useRef(undefined);
    const [showCopiedSource, setShowCopiedSource] = useState(false);

    const onTextChanged = (e) => {
        let text = e.currentTarget.value;
        dispatch(setText(text.substring(0, config.features.translation.maxLength)));
        // handleSendTimeOut();
    }

    const handleSendTimeOut = async () => {
        // if (sendTimeOutId.current) {
        //     clearTimeout(sendTimeOutId.current);
        // }
        // await new Promise(resolve => sendTimeOutId.current = setTimeout(resolve, 1500));
        // sendTimeOutId.current = undefined;
        dispatch(sendTranslation(sourceText));
    }

    const copySource = () => {
        if (sourceText) {
            navigator.clipboard.writeText(sourceText);
            setShowCopiedSource(true);
            setTimeout(() => {
                setShowCopiedSource(false)
            }, 2000);
        }
    };
    return (
        <div className="text textInput">
            <div className='inputInfo'>
                <div className='counterInfo'>
                    <span className='currentCount'>{sourceText.length.toLocaleString('fr-FR')}</span> / {config.features.translation.maxLength.toLocaleString('fr-FR')}
                </div>
                <button className="link" onClick={copySource}>{showCopiedSource ? <img src={check} alt="Copied" /> : <img src={copy} alt='Copy text' />}</button>
            </div>
            <textarea className="input" onChange={onTextChanged} value={sourceText} />
            <div className='buttonZone'>
                <button onClick={handleSendTimeOut} disabled={loading}>Tanslate</button>
            </div>
        </div>
    )
}

export default TextInput
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { GPTService } from "../../services/GptService";

export const setIndex = createAction('search/setIndex');
export const setFormat = createAction('search/setFormat');
export const setText = createAction('search/setText');

export const search = createAsyncThunk('search/search',
    async (a, thunkAPI) => {
        const service = GPTService;
        const state = thunkAPI.getState();
        return await service.search(state.search.text, state.search.index);
    }
)
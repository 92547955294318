import React, { useState } from 'react'
import config from "../../../config.json"
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store';
import translating from '../../../assets/images/translating.svg';
import { sendTranslation, setLanguage } from '../../../store/actions/translate';

let copy; let check;
const darkModeStorage = localStorage.getItem('darkMode');
const isDarkMode = darkModeStorage !== null && JSON.parse(darkModeStorage);
const darkMode = isDarkMode ? isDarkMode : config.DarkMode;
// Dynamically import styles based on dark mode
if (darkMode) {
    copy = require('../../../assets/images/copy_dark.svg').default;
    check = require('../../../assets/images/check_small_dark.svg').default;
} else {
    copy = require('../../../assets/images/copy.svg').default;
    check = require('../../../assets/images/check_small.svg').default;
}

const TranslatedText = () => {
    const dispatch = useAppDispatch();
    const sourceText = useAppSelector(state => state.translate.sourceText);
    const currentLanguage = useAppSelector(state => state.translate.language);
    const traduction = useAppSelector(state => state.translate.translated);
    const loading = useAppSelector(state => state.translate.loading);
    const [showCopiedTranslation, setShowCopiedTranslation] = useState(false);

    const onLanguageChanged = (e) => {
        dispatch(setLanguage(e.currentTarget.value));
        // dispatch(sendTranslation(sourceText));
    }

    const copyTranslation = (ev) => {
        ev.preventDefault();
        if (traduction) {
            navigator.clipboard.writeText(traduction);
            setShowCopiedTranslation(true);
            setTimeout(() => {
                setShowCopiedTranslation(false)
            }, 2000);
        }
    };
    const languages = config.features.translation.languages;
    return (
        <div className="text textOutput">
            <div className='controlsZone'>
                <select onChange={onLanguageChanged} value={currentLanguage}>
                    {languages.map(l => (<option value={l.key} key={`language-${l.key}`}>{l.value}</option>))}
                </select>
            </div>
            <div className='content' dangerouslySetInnerHTML={{ __html: traduction ? traduction : "" }}></div>
            <div className={`loading ${loading ? 'visible' : ''}`}><img src={translating} alt="Translation in progress" /></div>
            <button className='link copy' onClick={copyTranslation}>{showCopiedTranslation ? <img src={check} alt={"Copied"} /> : <img src={copy} alt={"Copy translation"} />}</button>
        </div>
    )
}

export default TranslatedText
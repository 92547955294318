import React from 'react'
import favorite_off from '../../../assets/images/prompts/favorite_off.svg'
import favorite_on from '../../../assets/images/prompts/favorite_on.svg'
import { useAppDispatch } from '../../../store/reducers/store'
import { toggleFavorite } from '../../../store/actions/prompts'

const PromptFavorite = ({ isFavorite, id }) => {
    const dispatch = useAppDispatch();

    const onToggleFavorite = (ev) => {
        ev.stopPropagation();
        dispatch(toggleFavorite(id));
    }

    return (
        <img className="favoriteIcon" src={isFavorite ? favorite_on : favorite_off} alt={`Favorite icon ${isFavorite ? "on" : "off"}`} onClick={onToggleFavorite} />
    )
}

export default PromptFavorite
import React from 'react'
import { search, setText } from "../../../store/actions/search.js";
import search_icon from '../../../assets/images/search/search.svg'
import { useAppDispatch, useAppSelector } from '../../../store/reducers/store.js';

const SearchBar = () => {
    const dispatch = useAppDispatch();
    const text = useAppSelector(state => state.search.text);

    const onSearch = () => {
        dispatch(search());
    }

    const onSearchTextChanged = (ev) => {
        dispatch(setText(ev.currentTarget.value));
    }

    const onKeyUp = (ev) => {
        if (ev.keyCode === 13) {
            ev.preventDefault();
            onSearch();
        }
    }

    return (
        <div className="searchBox">
            <img className="searchIcon" src={search_icon} alt="Search icon" />
            <input type="text" placeholder="Search" onChange={onSearchTextChanged} onKeyUp={onKeyUp} defaultValue={text} />
            <button onClick={onSearch}>Search</button>
        </div>
    )
}

export default SearchBar